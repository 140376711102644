import React, { useEffect } from 'react';
import { InputStyle } from './_style';
import { InputProps } from './_interface';
import { maxLengthCheck } from 'util/global';

function Input({ $width, $height, id, name, type, component, onChange, placeholder, error, style, ref, onKeyPress, value, disabled, errMsg, tooltipTxt, leftIco, maxLength }: InputProps) {
  function showTooltip(message) {
    const tooltip: any = document.getElementById('inputTooltip');
    tooltip.textContent = message;
    tooltip.style.display = 'block';

    // 일정 시간 후 툴팁 숨기기
    setTimeout(() => {
      tooltip.style.display = 'none';
    }, 2000);
  }

  // error, tooltiptxt 있을 때 잠깐 툴팁 표출
  useEffect(() => {
    if (error) {
      if (tooltipTxt) {
        showTooltip(tooltipTxt);
      }
    }
  }, [error, tooltipTxt]);

  return (
    <>
      <InputStyle.Wrap $width={$width} $height={$height}>
        <InputStyle.Input
          id={id}
          name={name}
          type={type ? type : 'text'}
          component={component}
          onChange={onChange}
          placeholder={placeholder}
          error={error}
          style={style}
          ref={ref}
          onKeyDown={onKeyPress}
          defaultValue={value}
          disabled={disabled}
          leftIco={leftIco}
          maxLength={maxLength}
          //  onInput={maxLength ? maxLengthCheck : undefined}
          onKeyUp={maxLength ? maxLengthCheck : undefined}
        />
        {error && (
          <div id="inputTooltip" className="inputTooltip">
            {tooltipTxt}
          </div>
        )}
        {component ? component : null}
        {error && <InputStyle.ErrMsg>{errMsg}</InputStyle.ErrMsg>}
      </InputStyle.Wrap>
    </>
  );
}

export default Input;
