import proj4 from 'proj4';
import { Point } from 'ol/geom';
import { transform } from 'ol/proj';
import { register } from 'ol/proj/proj4';

export const baseProjection = 'EPSG:4326';
export const targetProjection = 'EPSG:4326'; //EPSG:5186, EPSG:4326

interface TransProjection {
  latitude: number;
  longitude: number;
  targetProjection?: string;
}

export const extent5179 = [90112, 1192896, 2187264, 2765760];

/** Projection 설정 */
proj4.defs('EPSG:5176', '+proj=tmerc +lat_0=38 +lon_0=129.0028902777778 +k=1 +x_0=200000 +y_0=500000 +ellps=bessel +units=m +no_defs +towgs84=-115.80,474.99,674.11,1.16,-2.31,-1.63,6.43');
proj4.defs('EPSG:5183', '+proj=tmerc +lat_0=38 +lon_0=129 +k=1 +x_0=200000 +y_0=500000 +ellps=GRS80 +units=m +no_defs');
proj4.defs('EPSG:5179', '+proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
proj4.defs('EPSG:5186', '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs');
proj4.defs('EPSG:5187', '+proj=tmerc +lat_0=38 +lon_0=129 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs');
proj4.defs('EPSG:5185', '+proj=tmerc +lat_0=38 +lon_0=125 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs');
register(proj4);

/** 4326 좌표계 ---> 5179 반환타입 ARR */
export const trans4326To5186 = ({ latitude, longitude }: TransProjection) => {
  return new Point(transform([longitude, latitude], 'EPSG:4326', baseProjection)).getCoordinates();
};
/** 5186 좌표계 ---> 5179 반환타입 ARR */
export const trans5186To5179 = ({ latitude, longitude }: TransProjection) => {
  return new Point(transform([longitude, latitude], 'EPSG:5186', baseProjection)).getCoordinates();
};

export const trans5186To4326 = ({ latitude, longitude }: TransProjection) => {
  return new Point(transform([longitude, latitude], 'EPSG:5186', baseProjection)).getCoordinates();
};

export const transProjection = ({ latitude, longitude, targetProjection }: TransProjection) => {
  return new Point(transform([longitude, latitude], targetProjection, baseProjection)).getCoordinates();
};
