import React, { useEffect, useState } from 'react';
import { PaginationStyle } from './_style';
import { PageProps, PaginationProps } from './_interface';

const Pagination = ({ param, setParam, totalPages, btnSize }: PaginationProps<PageProps>) => {
  const [startRow, setStartRow] = useState(1);
  const [pageGroup, setPageGroup] = useState(1);
  const [pageList, setPageList] = useState<number[]>([]);

  //현재 페이지, 총 페이지수, 한번에 보여지는 컨텐츠 수(pageSize)가 바뀔 때 페이지그룹 재계산
  useEffect(() => {
    if (param.pageSize && param.pageNo) {
      setPageGroup(Math.ceil(param.pageNo / param.pageSize));
    }
  }, [param.pageNo, totalPages, param.pageSize]);

  //  console.log(totalPages);

  //페이지그룹이 바뀔때 그룹 시작 페이지넘버 갱신
  useEffect(() => {
    setStartRow((pageGroup - 1) * param.pageSize + 1);
  }, [pageGroup, param.pageSize]);

  //페이지그룹의 시작 페이지넘버, 총 페이지수, 페이지넘버가 바뀔 때 페이지그룹의 마지막 페이지넘버를 확인하여 잔여 컨텐츠를 집어넣음
  useEffect(() => {
    let endRow = pageGroup * param.pageSize > totalPages ? totalPages : pageGroup * param.pageSize;
    let newPageList: number[] = [];
    for (let i = startRow; i < endRow + 1; i++) {
      newPageList.push(i);
    }
    setPageList(newPageList);
  }, [startRow, totalPages, pageGroup, param.pageSize]);

  // 처음으로 버튼
  const onFirst = () => {
    setParam({
      ...param,
      pageNo: 1,
    });
  };

  // 마지막으로 버튼
  const onLast = () => {
    setParam({
      ...param,
      pageNo: totalPages,
    });
  };

  // 이전 버튼
  const onPrev = () => {
    if (pageGroup === 1) return;
    setParam({
      ...param,
      pageNo: (pageGroup - 1) * param.pageSize,
    });
  };

  // 다음 버튼
  const onNext = () => {
    if (pageGroup * param.pageSize > totalPages) return;
    setParam({
      ...param,
      pageNo: pageGroup * param.pageSize + 1,
    });
  };

  // (페이지) 넘버 버튼
  const onSetPage = (num: number) => {
    setParam({
      ...param,
      pageNo: num,
    });
  };

  return (
    <PaginationStyle.Wrap>
      {/* <PaginationStyle.First type={'button'} btnSize={btnSize} onClick={onFirst} disabled={totalPages && param.pageSize ? totalPages < param.pageSize || pageGroup === 1 : false}>
        &lt;&lt;
      </PaginationStyle.First> */}

      {totalPages < param.pageSize || pageGroup === 1 ? null : (
        <>
          <PaginationStyle.First type={'button'} btnSize={btnSize} onClick={onFirst} disabled={totalPages && param.pageSize ? totalPages < param.pageSize || pageGroup === 1 : false}>
            <span className={'hidden'}>맨 처음</span>
          </PaginationStyle.First>
        </>
      )}

      {totalPages < param.pageSize || pageGroup === 1 ? null : (
        <PaginationStyle.Prev type={'button'} btnSize={btnSize} onClick={onPrev} disabled={totalPages && param.pageSize ? totalPages < param.pageSize || pageGroup === 1 : false}>
          <span className={'hidden'}>이전</span>
        </PaginationStyle.Prev>
      )}

      {/* <PaginationStyle.Prev btnSize={btnSize}> 이전!! </PaginationStyle.Prev> */}

      <PaginationStyle.NumGroup>
        {/* {totalPages < param.pageSize || pageGroup === 1 ? null : (
          <>
            <PaginationStyle.First type={'button'} btnSize={btnSize} onClick={onFirst} disabled={totalPages && param.pageSize ? totalPages < param.pageSize || pageGroup === 1 : false}>
              1
            </PaginationStyle.First>
            <PaginationStyle.Ellipsis>...</PaginationStyle.Ellipsis>
          </>
        )} */}

        {/* {pageList.map((num) => {
          if (param.pageNo === num) {
            return (
              <PaginationStyle.Num key={num} btnSize={btnSize} onClick={() => onSetPage(num)} type="button" className="active">
                {num}
              </PaginationStyle.Num>
            );
          } else {
            return (
              <PaginationStyle.Num key={num} btnSize={btnSize} onClick={() => onSetPage(num)} type="button">
                {num}
              </PaginationStyle.Num>
            );
          }
        })} */}

        {pageList.map((num) => (
          <PaginationStyle.Num key={num} btnSize={btnSize} onClick={() => onSetPage(num)} type="button" className={param.pageNo === num ? 'active' : ''}>
            {num}
          </PaginationStyle.Num>
        ))}

        {/* {pageGroup * param.pageSize > totalPages - 1 ? null : (
          <>
            <PaginationStyle.Ellipsis>...</PaginationStyle.Ellipsis>
            <PaginationStyle.Last type={'button'} btnSize={btnSize} onClick={onLast} disabled={totalPages && param.pageSize ? pageGroup * param.pageSize > totalPages - 1 : false}>
              {totalPages}
            </PaginationStyle.Last>
          </>
        )} */}
      </PaginationStyle.NumGroup>
      {pageGroup * param.pageSize > totalPages - 1 ? null : (
        <PaginationStyle.Next type={'button'} btnSize={btnSize} onClick={onNext} disabled={totalPages && param.pageSize ? pageGroup * param.pageSize > totalPages - 1 : false}>
          <span className={'hidden'}>다음</span>
        </PaginationStyle.Next>
      )}

      {pageGroup * param.pageSize > totalPages - 1 ? null : (
        <>
          <PaginationStyle.Last type={'button'} btnSize={btnSize} onClick={onLast} disabled={totalPages && param.pageSize ? pageGroup * param.pageSize > totalPages - 1 : false}>
            <span className={'hidden'}>맨 마지막</span>
          </PaginationStyle.Last>
        </>
      )}

      {/* <PaginationStyle.Last type={'button'} btnSize={btnSize} onClick={onLast} disabled={totalPages && param.pageSize ? pageGroup * param.pageSize > totalPages - 1 : false}>
        &gt;&gt;
      </PaginationStyle.Last> */}
    </PaginationStyle.Wrap>
  );
};

export default Pagination;
