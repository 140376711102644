import React from 'react';
import styled from 'styled-components';
import IcoWarning from 'assets/images/header/ico-warning.svg';
import IcoClose from 'assets/images/ico-pop-close-light.svg';

const ModalAlertSt = {
  Wrap: styled.div`
    position: fixed;
    top: 62px;
    right: 40px;
    z-index: 9;
    width: 494px;
    height: 62px;
    border: 1px solid #f01a1a;
    background-color: #fff;
    box-shadow: 4px 4px 10px 3px #0000000f;
    display: flex;
    justify-content: space-between;
  `,
  TextWrap: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .icoWrap {
      width: 62px;
      height: 62px;
      background-color: #f01a1a;
      display: flex;
      align-items: center;
      justify-content: center;
      .ico {
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url(${IcoWarning}) no-repeat center;
      }
    }
    .main {
      margin-left: 20px;
      font-weight: 600;
    }
    .refreshBtn {
      margin-left: auto;
      border: 1px solid #a2a2a2;
      color: #525252;
      padding: 2px 4px;
      border-radius: 2px;
      cursor: pointer;
      &:hover {
        transition: all 0.4s;
        background-color: #959595;
        color: #ffffff;
      }
    }
    .closeBtn {
      margin-left: auto;
      margin-right: 12px;
      display: block;
      width: 24px;
      height: 24px;
      background: url(${IcoClose}) no-repeat center;
      cursor: pointer;
    }
  `,
};

//SSE
function ModalAlert({ alertData, setAlertData }) {
  const { content, createdDate, detId, siteId, siteName } = alertData || {};
  //   console.log('modal', alertData);

  // 새로고침 버튼 클릭
  const onClickRefresh = () => {
    window.location.reload();
  };

  // 닫기 버튼 클릭
  const onClickClose = () => {
    setAlertData({});
  };

  return (
    <>
      {content && (
        <ModalAlertSt.Wrap>
          <ModalAlertSt.TextWrap>
            <div className="icoWrap">
              <span className="ico"></span>
            </div>
            <div className="main">{content || '이상이 발생했습니다.'}</div>
            {/* <div className="refreshBtn" onClick={onClickRefresh}>
              새로고침
            </div> */}
            <div className="closeBtn" onClick={onClickClose}></div>
          </ModalAlertSt.TextWrap>
        </ModalAlertSt.Wrap>
      )}
    </>
  );
}

export default ModalAlert;
