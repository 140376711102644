import DatePicker, { DateObject } from 'react-multi-date-picker';
import { MonthsForLocale } from 'util/global';

function DatePickerInput({ name, onCustomChange }) {
  const monthsKo = MonthsForLocale('ko', 'short');

  const onChangePicker = (selectedDates: DateObject | DateObject[]) => {
    // range = false (날짜 하나 선택)
    if (selectedDates instanceof DateObject) {
      // YYYY-MM-DD로 변환
      const startDate = new DateObject(selectedDates)?.format('YYYY-MM-DD');

      onCustomChange && onCustomChange(startDate, name); // 상위 전달
    }

    // range =  true
    if (Array.isArray(selectedDates)) {
      // YYYY-MM-DD로 변환
      const [startDate, endDate] = selectedDates.map((ele) => new DateObject(ele.toDate()).format('YYYY-MM-DD'));
      console.log([startDate, endDate, name]);

      onCustomChange && onCustomChange(startDate, endDate, name); // onchange 이벤트
    }
  };

  return (
    <>
      <DatePicker
        className="light"
        onChange={onChangePicker}
        range={false}
        placeholder={'날짜 선택'}
        //   calendarPosition={'bottom-end'}
        dateSeparator={' - '}
        monthYearSeparator=" "
        numberOfMonths={1}
        //   months={['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']}
        months={monthsKo}
        weekDays={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
        format={'YYYY.MM.DD'}
        headerOrder={['LEFT_BUTTON', 'YEAR_MONTH', 'RIGHT_BUTTON']}
        //   minDate={new DateObject()}
        //   maxDate={new DateObject()}
      />
    </>
  );
}

export default DatePickerInput;
