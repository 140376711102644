import React, { useState, useEffect } from 'react';
import SiteHeader from 'components/organism/SiteHeader';
import styled from 'styled-components';
import DashboardMap from 'components/template/dashboard/DashboardMap';
import Table from 'components/atom/Table';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { detInfoState, overlayState } from 'store/Atoms';

import DashboardInfoCont from 'components/template/DashboardInfoCont';
import DashboardTable from 'components/template/dashboard/DashboardTable';
import ClusterOverlay from 'components/template/map/ClusterOverlay';

export const DashboardSt = {
  Wrap: styled.div`
    height: calc(100% - 64px);
    box-sizing: border-box;
    padding-top: 20px;
    width: 100%;
    display: flex;
    gap: 16px;
  `,
  //[좌측] 지도 및 하단 포함 영역
  MapContainer: styled.div`
    width: 1080px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  MapWrap: styled.div`
    height: calc(100% - 250px);
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    background-color: #fff;
  `,
  ListWrap: styled.div`
    overflow: hidden;
    flex: 1; // flex-grow
    /* height: 100%; */
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    background-color: #fff;
  `,
  //[우측] 정보, 조치내역, 센서차트 영역
  InfoContainer: styled.div`
    height: 100%;
    /* width: 41.5%; */
    width: 744px;
    max-width: 744px;
    flex: 1 0 auto;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    background-color: #fff;
  `,
  InfoWrap: styled.div``,
  WrapTitle: styled.div`
    height: 52px;
    background-color: #f1f4f9;
    padding: 0 24px;
    box-sizing: border-box;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
  `,
};

function Dashboard() {
  const detInfo = useRecoilValue(detInfoState); // 선택된 감지장치 정보
  const [overlayData, setOverlayData] = useRecoilState(overlayState);
  const [tabList, setTabList] = useState([
    { id: '1', text: '정보', active: true },
    //  { id: '2', text: '조치내역', active: false },
    { id: '3', text: '센서 차트', active: false },
  ]);

  return (
    <>
      {/* 지도 오버레이 팝업 */}
      <ClusterOverlay open={overlayData?.open} overlayList={overlayData?.overlayList} setTabList={setTabList} />

      {/* 현장정보 헤더 */}
      <SiteHeader />

      {/* 지도 및 상세 */}
      <DashboardSt.Wrap>
        {/* 좌측 지도 및 테이블 영역 */}
        <DashboardSt.MapContainer>
          {/* 상단 지도 영역 */}
          <DashboardSt.MapWrap>
            <DashboardSt.WrapTitle>{'작업 지도'}</DashboardSt.WrapTitle>
            {/* 지도 */}
            <DashboardMap />
          </DashboardSt.MapWrap>

          {/* 하단 테이블 영역 */}
          <DashboardSt.ListWrap>
            {/* 테이블 */}
            <DashboardTable setTabList={setTabList} />
          </DashboardSt.ListWrap>
        </DashboardSt.MapContainer>

        {/* 우측 상세 정보 영역 */}
        <DashboardSt.InfoContainer>
          <DashboardSt.WrapTitle>{detInfo?.detName}</DashboardSt.WrapTitle>
          {/* 탭 포함 상세 정보 영역  */}
          <DashboardInfoCont tabList={tabList} setTabList={setTabList} />
        </DashboardSt.InfoContainer>
      </DashboardSt.Wrap>
    </>
  );
}

export default Dashboard;
