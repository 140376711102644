import Lottie from 'lottie-react';
import { useRecoilValue } from 'recoil';
import { loaderState } from 'store/Atoms';
import animationData from 'util/lottieAnimation.json';

function Loader(props) {
  const { show: loaderShow } = useRecoilValue(loaderState);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  if (loaderShow) {
    return (
      <div>
        <div style={{ position: 'fixed', left: 0, top: 0, width: '100%', height: '100%', zIndex: 999, background: 'rgba(0,0,0,.5)' }}>
          <Lottie {...defaultOptions} style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)', width: '8%', height: 'auto' }} />;
        </div>
      </div>
    );
  }
  return null;
}

export default Loader;
