import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { UserInfoProps } from './_interface';

// 새로운 저장소 객체 생성
export function newPersistAtom(key: string = 'sessionStorage') {
  // 스토리지에 저장하는 기능
  const { persistAtom } = recoilPersist({
    key: key,
    storage: sessionStorage || localStorage || undefined, // localStorage 또는 sessionStorage
  });
  return persistAtom;
}

// 초기 테마 저장 (시스템 기본값)
const isBrowserDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
if (!sessionStorage.getItem('theme')) {
  sessionStorage.setItem('theme', isBrowserDarkMode ? 'dark' : 'light');
}

// 테마 state
export const themeState = atom<string>({
  key: 'themeState',
  default: isBrowserDarkMode ? 'dark' : 'light',
});

// 로더 상태
export const loaderState = atom({
  key: 'loaderState',
  default: {
    show: false,
  },
});

// 로그인 후 유저 정보
export const userInfoState = atom<UserInfoProps>({
  key: 'userData',
  default: {},
  effects_UNSTABLE: [newPersistAtom('userInfo')], // persist 적용
});

// 전체 사이트 리스트 및 현재 사이트 아이디
export const siteInfoState = atom({
  key: 'siteData',
  default: {
    siteId: '',
  },
  effects_UNSTABLE: [newPersistAtom('siteInfo')], // persist 적용
});

// 함체 정보 ?
export const caseInfoState = atom({
  key: 'caseData',
  default: {},
  effects_UNSTABLE: [newPersistAtom('caseInfo')], // persist 적용
});

// 감지장치 정보 (detId + a)
export const detInfoState = atom({
  key: 'detData',
  default: {
    autoScroll: true,
  },
  effects_UNSTABLE: [newPersistAtom('detInfo')], // persist 적용
});

// 오버레이 정보
export const overlayState = atom({
  key: 'overlayState',
  default: {
    overlayList: [],
    open: false,
  },
});

// 알람 재요청할지 여부
export const refetchAlertState = atom({
  key: 'refetchAlertState',
  default: {
    refetch: false,
  },
});
