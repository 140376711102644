import React, { useMemo, useRef, useState, useEffect, useCallback } from 'react';
import Button from 'components/atom/Button';
import styled from 'styled-components';
import IcoUser from 'assets/images/header/ico-user.svg';
import IcoBell from 'assets/images/header/ico-bell.svg';
import IcoBellA from 'assets/images/header/ico-bell-active.svg';
import { useOutsideClick } from 'util/useOutsideClick';
import { fetchLogout } from 'service/common';
import { HeaderSt } from './_style';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userInfoState, refetchAlertState } from 'store/Atoms';
import { useNavigate } from 'react-router-dom';
import PopupVersion from 'popups/PopupVersion';
import PopupContact from 'popups/PopupContact';
import PopupAlert from 'popups/PopupAlert';
import PopupAlertList from 'popups/PopupAlertList';
import { useQueryState } from 'hooks/reactQuery/useQueryState';
import { getAlerts, patchAlerts } from 'service/env';
import useMutateHook from 'hooks/reactQuery/useMutateHook';
import { useQueryClient } from '@tanstack/react-query';
import Timer from 'components/atom/Timer';
import PopupLoginHistory from 'popups/PopupLoginHistory';

function Header(props) {
  const queryClient = useQueryClient();
  const dataUpdatedAt = sessionStorage.getItem('dataUpdatedAt') || '-';
  const navigate = useNavigate();
  const [refetchAlert, setRefetchAlert] = useRecoilState(refetchAlertState); // 알람 요청 여부
  const userInfoValue = useRecoilValue(userInfoState);
  const comboRef = useRef<HTMLDivElement>(null); //유저 버튼 영역

  const [openUser, setOpenUser] = useState(false);
  const [openLoginHistory, setOpenLoginHistory] = useState(false); //인증 이력 팝업
  const [openPopVersion, setOpenPopVersion] = useState(false); //버전정보 팝업
  const [openPopContact, setOpenPopContact] = useState(false); //문의하기 팝업
  const [openAlert, setOpenAlert] = useState(false); // 문의하기 - 클립보드 복사 완료시
  const [openAlertList, setOpenAlertList] = useState(false); // 알림 리스트
  const [refreshTimer, setRefreshTimer] = useState(true);

  // 읽지않은 알림 조회
  //   const { data: alertsData } = useQueryState(['alert'], () => getAlerts());
  const [alertsData, setAlertsData] = useState<any>(null);
  const { alerts, alertIds } = alertsData || {};

  // [init] 알람 목록 처음 요청 (recoil 상태 변경)
  useEffect(() => {
    setRefetchAlert({ refetch: true });
    return () => {};
  }, [setRefetchAlert]);

  // 알람 목록 요청 (recoil 상태값 관찰)
  useEffect(() => {
    // 알람 조회 함수 (axios)
    const fetchAlert = async () => {
      try {
        const alertsData = await getAlerts();
        setAlertsData(alertsData);
      } catch (e) {
        console.log(e);
      } finally {
        setRefetchAlert({ refetch: false });
      }
    };
    // refetch 값 true
    if (refetchAlert.refetch) {
      // console.log('refetch Alert~!');
      fetchAlert(); // 알람 조회
    }
  }, [refetchAlert.refetch, setRefetchAlert]);

  // 알림 읽음 처리
  const { mutate: readAlerts } = useMutateHook({ fetchApi: patchAlerts, invalidQuery: [['alert']] });

  const toggleUserMenu = () => {
    setOpenUser((prev) => !prev);
  };

  // 외부영역 클릭 이벤트 - 메뉴 박스 닫기
  useOutsideClick(() => setOpenUser(false), comboRef);

  // 접속한 유저명
  const userName = useMemo(() => {
    return userInfoValue.email?.split('@')?.[0];
  }, [userInfoValue]);

  // 비밀번호 변경 버튼 클릭
  const goPwChangePg = () => {
    navigate('/pwChange');
  };

  // 로그아웃 버튼 클릭
  const onClickLogout = () => {
    // fetchLogout()
    setOpenAlert(true);
  };

  // 문의하기 버튼 클릭
  const onClickContact = () => {
    setOpenPopContact(true);
  };

  // 버전 정보 버튼 클릭
  const onClickVersion = () => {
    setOpenPopVersion(true);
  };

  // 인증 이력 버튼 클릭
  const onClickLoginHistory = () => {
    setOpenLoginHistory(true);
  };

  // 알림 버튼 클릭
  const onClickAlertList = () => {
    setOpenAlertList(true);
  };

  // 알림창 닫기 버튼 클릭
  const closeAlert = async () => {
    const newAlertIds = alertIds;

    if (newAlertIds?.length > 0) {
      // 알람 읽기 요청
      await readAlerts([...newAlertIds], {
        onSuccess(data, variables, context) {
          setRefetchAlert({ refetch: true }); // 알람 목록 재요청
        },
        onError(error, variables, context) {},
        onSettled(data, error, variables, context) {
          setOpenAlertList(false); // 성공, 실패 상관없이 알림창 닫기
        },
      });
    } else {
      setOpenAlertList(false); // 성공시-알림창 닫기
    }
  };

  // 안읽은 알람 유무
  const newAlert = useMemo(() => {
    return alerts?.length > 0;
  }, [alerts]);

  // 업데이트 버튼 클릭
  const onClickRefresh = async () => {
    //  queryClient.resetQueries();
    window.location.reload();
    await setRefreshTimer(false);
    setRefreshTimer(true);
  };

  const onClickLogo = () => {
    window.location.reload();
  };
  return (
    <>
      <HeaderSt.Wrap>
        <HeaderSt.TitleWrap onClick={onClickLogo}>
          <div className="title">스마트 파이프</div>
        </HeaderSt.TitleWrap>
        <HeaderSt.UtilWrap>
          {/* <div className="updateTime">2024-05-01 16:20 업데이트</div> */}
          <div className="updateTime">
            <span className="icoUpdate" onClick={onClickRefresh}></span>
            <p className="text">{dataUpdatedAt} 업데이트</p>
            <span className="countDown">{refreshTimer && <Timer />}</span>
          </div>

          <HeaderSt.AlertWrap className={`${newAlert && 'newAlert'}`}>
            <Button $width={44} $height={40} icoPosition="left" ico={IcoBell} icoHover={IcoBellA} icoSize={24} style={{ marginRight: '8px' }} colorType="basicToRed" active={openAlertList} onClick={onClickAlertList} />
          </HeaderSt.AlertWrap>

          <HeaderSt.UserMenuWrap ref={comboRef}>
            <Button $height={40} $fontWeight={500} text={userName} icoPosition="left" ico={IcoUser} icoSize={24} colorType="basicToBlue" onClick={toggleUserMenu} />

            <HeaderSt.UserMenu className={openUser ? 'isOpen' : ''}>
              <li onClick={goPwChangePg}>비밀번호 변경</li>
              <li onClick={onClickContact}>문의하기</li>
              <li onClick={onClickVersion}>버전 정보</li>
              <li onClick={onClickLoginHistory}>인증 이력</li>
              <li onClick={onClickLogout}>로그아웃</li>
            </HeaderSt.UserMenu>
          </HeaderSt.UserMenuWrap>
        </HeaderSt.UtilWrap>
      </HeaderSt.Wrap>

      {/* 로그아웃 확인 알림창 */}
      {openAlert && <PopupAlert title={'로그아웃'} text={'로그아웃 하시겠습니까?'} close={() => setOpenAlert(false)} leftFunc={fetchLogout} />}

      {/* 문의하기 팝업 */}
      {openPopContact && <PopupContact close={() => setOpenPopContact(false)} />}
      {/* 버전 정보 팝업 */}
      {openPopVersion && <PopupVersion close={() => setOpenPopVersion(false)} />}
      {/* 알림 리스트 */}
      {openAlertList && <PopupAlertList close={closeAlert} alerts={alerts} />}
      {/* 인증 이력 팝업 */}
      {openLoginHistory && <PopupLoginHistory close={() => setOpenLoginHistory(false)} />}
    </>
  );
}

export default Header;
