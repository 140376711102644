import React from 'react';
import { PopupFrameSt } from './_style';

// header (close)
// content
// button (full, left, right)
// 커스텀용
function PopupFrame({
  title = '현장 변경',
  children,
  leftText,
  rightText,
  leftFunc,
  rightFunc,
  close,
  $width,
}: {
  title?: string;
  children: React.ReactNode;
  leftText?: string;
  rightText?: string;
  leftFunc?: () => void;
  rightFunc?: () => void;
  close?: () => void;
  $width?: number;
}) {
  return (
    <>
      <PopupFrameSt.Dimmed></PopupFrameSt.Dimmed>
      <PopupFrameSt.Popup $width={$width}>
        {/* header */}
        <PopupFrameSt.HeaderWrap>
          <p className="title">{title}</p>
          <button className="closeBtn" onClick={close}></button>
        </PopupFrameSt.HeaderWrap>

        {/* content */}
        <PopupFrameSt.ContWrap>{children}</PopupFrameSt.ContWrap>

        {/* footer */}
        <PopupFrameSt.Footer className={!rightText ? 'full' : ''}>
          <PopupFrameSt.BtnWrap className={!rightText ? 'full' : ''}>
            {leftText ? (
              <button onClick={leftFunc} className={`${leftText === '취소' ? 'red' : ''}`}>
                {leftText}
              </button>
            ) : null}
            {rightText ? <button onClick={rightFunc ? rightFunc : close}>{rightText}</button> : null}
            {/* full */}
            {!rightText && <button onClick={close}>확인</button>}
          </PopupFrameSt.BtnWrap>
        </PopupFrameSt.Footer>
      </PopupFrameSt.Popup>
    </>
  );
}

export default PopupFrame;
