import VectorModule from './VectorModule';
import { Feature } from 'ol';
import { LineString } from 'ol/geom';

class PipeLineModule extends VectorModule {
  //
  // 단일 피쳐 생성 (속성 정보 추가)
  public createPipeLineFeature(data: any, layerId: string) {
    const points = [
      [data.line?.[0].x, data.line?.[0].y],
      [data.line?.[1].x, data.line?.[1].y],
    ];
    const lineStringFeature = new Feature({
      id: 'pipeLine',
      geometry: new LineString(points),
      properties: {
        ...data,
        layerId,
      },
    });

    return lineStringFeature;
  }

  // 피쳐 배열 리턴
  public createPipeLineFeatures(datas: any[], layerId: string) {
    return datas?.map((data) => {
      return this.createPipeLineFeature(data, layerId);
    });
  }
}

export default PipeLineModule;
