import React, { useState } from 'react';
import { LoginStyle, PwBoxSt } from './_style';
import { useNavigate } from 'react-router-dom';
import useMutateHook from 'hooks/reactQuery/useMutateHook';
import { postPwReset } from 'service/common';
import useInputChange from 'hooks/useInputChange';

// 비밀번호 초기화
function PwReset(props) {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [form, setForm, onChange] = useInputChange({});

  const { mutate } = useMutateHook({ fetchApi: postPwReset });

  const isValidForm = () => {
    // 입력 없이 빈칸일때
    if (!form?.email) {
      setError(true);
      // setErrorMsg('이메일을 입력하세요.');
      setErrorMsg('이메일 형식이 아닙니다.');
      return false;
    }
    if (form?.email) {
      return true;
    }
    return false;
  };

  const onClickConfirmBtn = async () => {
    //유효성 검사
    if (isValidForm()) {
      await mutate(
        { email: form?.email },
        {
          onSuccess(data, variables, context) {
            alert('입력하신 이메일로 초기화 비밀번호가 발송되었습니다');
            // 페이지 이동
            window.location.href = '/';
          },
          async onError(error: any, variables, context) {
            console.log(error);
            // setError(true);
            // setErrorMsg('임시 비밀번호 전송 실패');

            let status = error?.data?.status;
            let msg = error?.data?.message;
            await setErrorMsg(msg);
            setError(true);
          },
        },
      );
    }
  };

  return (
    <LoginStyle.Wrap>
      <PwBoxSt.Box>
        <PwBoxSt.Title>
          비밀번호 초기화
          <PwBoxSt.Close
            onClick={() => {
              navigate(-1);
            }}
          ></PwBoxSt.Close>
        </PwBoxSt.Title>

        <PwBoxSt.HelpWrap>
          <span className="ico"></span>
          <span className="helpText">가입한 이메일 계정(아이디)으로 임시 비밀번호가 전송됩니다.</span>
        </PwBoxSt.HelpWrap>
        <LoginStyle.InputWrap className={error ? 'id error' : 'id'}>
          <LoginStyle.Input type={'text'} id={'email'} placeholder={'이메일을 입력하세요'} name={'email'} onChange={onChange} />
        </LoginStyle.InputWrap>
        <LoginStyle.Msg className={error ? 'error' : undefined}>{errorMsg}</LoginStyle.Msg>

        <PwBoxSt.ConfirmBtn onClick={onClickConfirmBtn}>비밀번호 전송</PwBoxSt.ConfirmBtn>
      </PwBoxSt.Box>
    </LoginStyle.Wrap>
  );
}

export default PwReset;
