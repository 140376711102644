import OlCore from 'olMap/OlCore';
//
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Select from 'ol/interaction/Select.js';
// module
import PointModule from 'olMap/Module/PointModule';
// ico
import IcoPP from 'assets/images/map/ico-pp-marker.svg';
import IcoPP_A from 'assets/images/map/ico-pp-marker-active.svg';
import IcoPP_E from 'assets/images/map/ico-pp-marker-error.svg';
import IcoBP from 'assets/images/map/ico-bp-marker.svg';
import IcoBP_A from 'assets/images/map/ico-bp-marker-active.svg';
import { Cluster } from 'ol/source';
import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { Collection, Feature, Overlay } from 'ol';

import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';
import { click } from 'ol/events/condition';
import { createEmpty, extend, getHeight, getWidth } from 'ol/extent.js';

class ObjectPoint extends PointModule {
  private pointLayer: VectorLayer<VectorSource>;
  private clusterLayer: VectorLayer<VectorSource>;

  private pointClickEvent?: Select;
  private clusterClickEvent?: Select;

  //레이어 초기화
  constructor(core: OlCore) {
    super(core);

    // 감지장치 포인트 레이어
    this.pointLayer = new VectorLayer<VectorSource>({
      properties: {
        id: 'detPointLayer',
      },
      // minZoom: 18,
      visible: true,
      zIndex: 4,
    });
    // 클러스터 레이어
    this.clusterLayer = new VectorLayer<VectorSource>({
      properties: {
        id: 'clusterLayer',
      },
      // maxZoom: 18,
      // animationDuration: 400,
      visible: true,
      zIndex: 4,
    });

    this.setLayers([this.pointLayer, this.clusterLayer]);
  }

  /* ------------------ DRAWING ------------------ */
  // point : map 지도 조회 데이터 그리기
  public draw(data: any) {
    let caseAll: any[] = []; // 모든 case
    data?.cases?.map((ele: any) => {
      let caseId = ele.caseId;
      let coordinate = ele.point;
      let caseDets = ele.caseDets;

      // 필요한 case 데이터
      let newCase = { caseId, coordinate, caseDets };
      caseAll.push(newCase);
    });

    // 피쳐 배열 생성
    const features = this.createPointFeatures(caseAll);
    // 피쳐 벡터 소스
    const source = this.createSource(features);

    // 포인트 레이어
    this.pointLayer.setSource(source);
    this.pointLayer.setStyle(this.onPointStyle);

    //  const clusterSource = new Cluster({
    //    source: source,
    //    distance: 10,
    //  });

    //  클러스터 레이어
    //  this.clusterLayer.setSource(clusterSource);
    //  this.clusterLayer.setStyle(this.onClusterStyle);

    // 레이어로 이동
    this.moveLayer('detPointLayer', { padding: [130, 130, 130, 130], duration: 400, maxZoom: 20 });
  }

  /* ------------------ EVENT ------------------ */

  // 감지장치 포인트 클릭 이벤트
  public addSelectClick(setOverlayData) {
    this.pointClickEvent = new Select({
      layers: [this.pointLayer],
      condition: click,
      style: (feature, resolution) => {
        return this.onPointStyleActive(feature, resolution);
      },
    });

    this.pointClickEvent.on('select', (e) => {
      //e.target.getFeatures는 Collection으로 반환한다.
      const targetCollection = e.target.getFeatures() as Collection<Feature>;

      if (targetCollection.getLength()) {
        const feature = targetCollection.getArray()[0];

        if (feature) {
          let properties = feature.getProperties().properties;
          // 오버레이 팝업 데이터
          let overlayList = properties?.caseDets;
          setOverlayData((prev) => ({ ...prev, overlayList })); // 오버레이 팝업 데이터 전달

          this.openOverlay({ feature, setOverlayData });
        }
      } else {
        setOverlayData((prev) => ({ ...prev, open: false })); // 오버레이 닫기
      }
    });

    // 지도에 클릭 이벤트 추가
    this.core.mapInstance.addInteraction(this.pointClickEvent);
  }

  // 감지장치 포인트 레이어에서 피쳐 찾기
  public getFeatureById({ caseId }) {
    let targetFeature;

    let features: Feature[] = this.pointLayer?.getSource()?.getFeatures() || [];

    features?.map((feature: Feature) => {
      let properties = feature.getProperties().properties;

      if (properties?.caseId === caseId) {
        targetFeature = feature;
      }
    });

    return targetFeature;
  }

  // Select 객체 - 선택할 피쳐 넣기(지도 직접클릭 x)
  public dynamicSelect(feature: Feature) {
    //  feature.setStyle(this.onClusterStyleActive);
    this.pointClickEvent?.getFeatures()?.push(feature);
  }

  // Select 객체 - 선택할 피쳐 넣기(지도 직접클릭 x)
  public resetSelect() {
    this.pointClickEvent?.getFeatures()?.clear();
  }

  // 선택 이벤트 (클러스터)
  public clusterSelect(setOverlayState, setOverlayList) {
    this.clusterClickEvent = new Select({
      layers: [this.clusterLayer],
      condition: click,
      style: (feature, resolution) => {
        return this.onClusterStyleActive(feature, resolution);
        //   if (feature.getProperties().features?.length) {
        //     return this.onClusterStyleActive(feature, resolution);
        //   } else {
        //     //  this.onClusterStyleActive(feature, resolution);
        //   }
      },
    });

    this.clusterClickEvent.on('select', (e) => {
      console.log('select click');
      //e.target.getFeatures는 Collection으로 반환한다.
      const targetCollection = e.target.getFeatures() as Collection<Feature>;

      //선택 객체 추출
      this.selectedFeature = targetCollection.getArray()[0];
      if (targetCollection.getLength()) {
        const features = targetCollection.getArray()[0].getProperties().features;
        // 1개 이상
        if (features.length > 0) {
        }

        // 2개 이상
        if (features.length > 1) {
          // 오버레이 위치
          const extent = targetCollection.getArray()[0].getGeometry()!.getExtent();
          this.clusterOverlay.setPosition([extent[0], extent[1]]);

          setOverlayState(true); // 오버레이 표출

          // 오버레이 팝업 데이터
          let overlayList = features.map((feature) => {
            return { ...feature.getProperties().properties };
          });
          setOverlayList(overlayList); // 오버레이 팝업 데이터 전달
        }
      } else {
        setOverlayState(false);
      }
    });

    // 지도에 클릭 이벤트 추가
    this.core.mapInstance.addInteraction(this.clusterClickEvent);
  }

  // 클러스터 선택 해제
  public clusterSelectClear() {
    this.clusterClickEvent?.getFeatures().clear();
  }

  // 지도 click 이벤트
  public addMapClickEvent() {
    this.core.mapInstance.on('click', async (evt) => {
      let feature = this.core.mapInstance.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
        let properties = feature.getProperties();
        // 2개 이상 -> cluster source
        if (properties?.features?.length > 1) {
        }
        return feature;
      });

      // 클러스터 레이어내의 피쳐 감지
      // let clusterFeatures = await this.clusterLayer.getFeatures(evt.pixel);
    });
  }

  // 지도 move 이벤트
  public addMapMoveEvent() {
    //TEST: moveend
    this.core.mapInstance.on('moveend', (evt) => {
      // var newZoom = this.core.mapInstance.getView().getZoom() || 0;
      // console.log(newZoom);
    });

    //pointermove
    this.core.mapInstance.on('pointermove', async (evt) => {
      // 클러스터 레이어내의 피쳐 감지
      let clusterFeatures = await this.clusterLayer.getFeatures(evt.pixel);

      // 일반 피쳐 감지
      let feature = this.core.mapInstance.forEachFeatureAtPixel(evt.pixel, function (feature: any) {
        if (feature?.get('id') === 'detPoint') {
          return feature;
        } else {
          return null;
        }
      });

      //커서 모양 변경
      if (clusterFeatures[0] || feature) {
        document.body.style.cursor = 'pointer';
      } else {
        document.body.style.cursor = 'default';
      }
    });
  }

  /* ------------------ STYLING ------------------ */

  // 감지장치 포인트 스타일
  private onPointStyle(feature, resolution) {
    let properties = feature.getProperties().properties;
    //  console.log('properties', properties);

    let detLength = properties?.caseDets?.length || 0;

    return [
      new Style({
        image: new Circle({
          radius: 14,
          fill: new Fill({
            color: '#ffffff',
          }),
          stroke: new Stroke({
            width: 1,
            color: '#000000',
          }),
        }),
        text: new Text({
          text: String(detLength),
          font: '600 16px Pretendard',
          fill: new Fill({ color: '#000000' }),
        }),
      }),
    ];
  }
  // 감지장치 포인트 스타일
  private onPointStyleActive(feature, resolution) {
    let properties = feature.getProperties().properties;
    console.log('properties', properties);

    let detLength = properties?.caseDets?.length || 0;

    return [
      new Style({
        image: new Circle({
          radius: 14,
          fill: new Fill({
            color: '#393939',
          }),
          stroke: new Stroke({
            width: 1,
            color: '#ffffff',
          }),
        }),
        text: new Text({
          text: String(detLength),
          font: '600 16px Pretendard',
          fill: new Fill({ color: '#ffffff' }),
        }),
      }),
    ];
  }

  // 클러스터 레이어 스타일
  public onClusterStyle(feature, resolution) {
    let features = feature.getProperties().features; // cluster source의 경우 feature은 여러개
    let featuresLength = features?.length;

    if (featuresLength > 1) {
      return [
        new Style({
          image: new Circle({
            radius: featuresLength > 5 ? 26 : 18,
            fill: new Fill({
              color: 'rgba(255, 255, 255, 1)',
            }),
            stroke: new Stroke({
              width: 1,
              color: '#393939',
            }),
          }),
          text: new Text({
            text: String(featuresLength),
            font: '600 16px Pretendard',
            // fill: new Fill({ color: '#000000' }),
          }),
        }),
      ];
    } else {
      // 1개일때
      let properties = feature.getProperties().properties;

      let typeCode = properties?.type?.code;
      let detIcon = typeCode === 'pp' ? IcoPP : typeCode === 'bp' ? IcoBP : undefined;

      return new Style({
        image: new Icon({
          opacity: 1,
          src: detIcon,
        }),
      });
    }
  }

  public onClusterStyleActive(feature, resolution) {
    let features = feature.getProperties().features; // cluster source의 경우 feature은 여러개
    let featuresLength = features?.length;

    if (featuresLength > 1) {
      return [
        new Style({
          image: new Circle({
            radius: featuresLength > 5 ? 26 : 18,
            fill: new Fill({
              color: '#393939',
            }),
            stroke: new Stroke({
              width: 1,
              color: '#fff',
            }),
          }),
          text: new Text({
            text: String(featuresLength),
            font: '600 16px Pretendard',
            fill: new Fill({ color: '#fff' }),
            // fill: new Fill({ color: '#000000' }),
          }),
        }),
      ];
    } else {
      // 1개일때
      let properties = feature.getProperties().properties;

      let typeCode = properties?.type?.code;
      let detIcon = typeCode === 'pp' ? IcoPP : typeCode === 'bp' ? IcoBP : undefined;

      return new Style({
        image: new Icon({
          opacity: 1,
          src: detIcon,
        }),
      });
    }
  }

  public fitVectorSource(source) {
    // 해당 레이어에 fit
    let extent = source?.getExtent();
    console.log(extent);

    this.core.mapInstance.getView().fit(extent, { padding: [130, 130, 130, 130], duration: 400, maxZoom: 20 });
  }
}

export default ObjectPoint;
