import Button from 'components/atom/Button';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DateObject } from 'react-multi-date-picker';

import styled from 'styled-components';
import IcoMenu from 'assets/images/siteHeader/ico-menu.svg';
import IcoBuildingBlue from 'assets/images/siteHeader/ico-building-blue.svg';
import PopupSiteChange from 'popups/PopupSiteChange';
import { getDateDiff, getDateString } from 'util/global';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { siteInfoState } from 'store/Atoms';
import { useQueryState } from 'hooks/reactQuery/useQueryState';
import { getSites } from 'service/env';

export const SiteHeaderSt = {
  Wrap: styled.div`
    width: 100%;
    height: 64px;
    padding: 0 24px;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    box-shadow: 0px 2px 8px 0px #0000001a;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  TitleWrap: styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    > .ico {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(${IcoBuildingBlue}) no-repeat center;
    }
    > p {
      color: #0076ff;
      font-weight: 600;
      font-size: 18px;
    }
  `,
  UtilWrap: styled.div`
    display: flex;
  `,
  DateCont: styled.div`
    margin-right: 77px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    > ul {
      display: flex;
      align-items: center;
      gap: 36px;
      > li {
        display: flex;
        align-items: center;
        > .title {
          color: #777777;
          line-height: 14px;
          padding-right: 10px;
          border-right: 1px solid #dfdfdf;
        }
        > .cont {
          > .start {
            margin-left: 10px;
          }
        }
      }
    }
  `,
};

function SiteHeader() {
  const [siteInfo, setSiteInfo] = useRecoilState(siteInfoState);
  const { siteId } = siteInfo;

  const [curSite, setCurSite] = useState<any>({}); // 현재 사이트 정보
  const [openPop, setOpenPop] = useState(false); // 현장 변경 팝업 오픈

  // 현장 조회
  const { data: sites, isSuccess } = useQueryState(['sites'], () => getSites());

  useEffect(() => {
    if (isSuccess) {
      if (!siteId) {
        setSiteInfo((prev) => ({
          ...prev,
          siteId: sites?.[0].siteId,
        }));
      }
      // console.log('save?');
      // setSiteInfo((prev) => ({
      //   ...prev,
      //   sites: sites,
      // }));
    }
  }, [isSuccess, siteId]);

  // siteId 변경시, 해당 사이트 정보 state 저장
  useEffect(() => {
    if (siteId) {
      setCurSite(sites?.find((ele) => ele.siteId === siteId));
    }
  }, [sites, siteId]);

  return (
    <>
      <SiteHeaderSt.Wrap>
        <SiteHeaderSt.TitleWrap>
          <span className="ico"></span>
          <p>{curSite?.siteName || '-'}</p>
        </SiteHeaderSt.TitleWrap>
        <SiteHeaderSt.UtilWrap>
          <SiteHeaderSt.DateCont>
            <ul>
              <li>
                <div className="title">시공기간</div>
                <div className="cont">
                  <span className="start">{getDateString(curSite?.startDate) || '-'}</span> ~ <span className="end">{getDateString(curSite?.endDate) || '-'}</span>
                </div>
              </li>
              <li>
                <div className="title">시스템 가동일자</div>
                <div className="cont">
                  <span className="start">
                    {curSite?.openDate || '-'}
                    {/* {getDateString(curSite?.openDate) || '-'}({getDateDiff(new DateObject(), curSite.openDate)}) */}
                  </span>
                </div>
              </li>
            </ul>
          </SiteHeaderSt.DateCont>
          <Button text="현장 변경" $fontWeight={500} style={{ background: '#fff' }} ico={IcoMenu} icoPosition="left" onClick={() => setOpenPop(true)} />
        </SiteHeaderSt.UtilWrap>
      </SiteHeaderSt.Wrap>

      {/* 현장변경 팝업 */}
      {openPop && <PopupSiteChange close={() => setOpenPop(false)} siteId={siteId} sites={sites} />}
    </>
  );
}

export default React.memo(SiteHeader);
