import React from 'react';
import { PopupFrameASt as PopupFrameSt } from './_style';

// Alert 용 작은 팝업
function PopupFrameA({
  title,
  children,
  leftText,
  rightText,
  leftFunc,
  rightFunc,
  close,
  $width,
}: {
  title?: string;
  children: React.ReactNode;
  leftText?: string;
  rightText?: string;
  leftFunc?: () => void;
  rightFunc?: () => void;
  close?: () => void;
  $width?: number;
}) {
  return (
    <>
      <PopupFrameSt.Dimmed></PopupFrameSt.Dimmed>
      <PopupFrameSt.Popup $width={$width}>
        {/* header */}
        {title && (
          <PopupFrameSt.HeaderWrap>
            <p className="title">{title}</p>
            <button className="closeBtn" onClick={close}></button>
          </PopupFrameSt.HeaderWrap>
        )}

        {/* content */}
        <PopupFrameSt.ContWrap className={`${!leftText && 'noFooter'} ${!title && 'noTitle'}`}>{children}</PopupFrameSt.ContWrap>

        {/* footer - 버튼이 최소 하나 이상 */}
        {leftText && (
          <PopupFrameSt.Footer className={!rightText ? 'full' : ''}>
            <PopupFrameSt.BtnWrap className={!rightText ? 'full' : ''}>
              {leftText ? (
                <button onClick={leftFunc} className={`${leftText === '취소' ? 'red' : ''}`}>
                  {leftText}
                </button>
              ) : null}
              {rightText ? <button onClick={rightFunc ? rightFunc : close}>{rightText}</button> : null}
            </PopupFrameSt.BtnWrap>
          </PopupFrameSt.Footer>
        )}
      </PopupFrameSt.Popup>
    </>
  );
}

export default PopupFrameA;
