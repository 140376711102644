import { useState, useEffect, useLayoutEffect } from 'react';
//react-query
import { QueryKey, UndefinedInitialDataOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import { DateObject } from 'react-multi-date-picker';

// useQuery - Base
// 쿼리로 받아온 데이터를 바로 사용하지 않고 state 저장후 사용시 깜빡임 개선
export function useQueryState(keys: any, fetchApi: any, options?: any) {
  const { data, isLoading, isSuccess, error, refetch, dataUpdatedAt, errorUpdatedAt } = useQuery({ queryKey: keys, queryFn: fetchApi, ...options });
  const [propData, setPropData] = useState<any>(null);

  // 업데이트 시간 저장
  useEffect(() => {
    //  console.log(new Date(dataUpdatedAt)?.toLocaleString());
    let dataUpdateTime = new DateObject(dataUpdatedAt).format('YYYY-MM-DD HH:mm:ss');
    let errorUpdateTime = new DateObject(errorUpdatedAt).format('YYYY-MM-DD HH:mm:ss');

    sessionStorage.setItem('dataUpdatedAt', dataUpdateTime);
    sessionStorage.setItem('errorUpdateTime', errorUpdateTime);
  }, [dataUpdatedAt, errorUpdatedAt]);

  //결과  데이터가 있든 없든 결과값 그대로 전달
  useLayoutEffect(() => {
    //  if (data) {
    setPropData(data);
    //  }
  }, [data]);

  return { data: propData, isLoading, isSuccess, error, refetch };
}

// 예시) query-key 이용하여 데이터 가져오기
export function useGetQueryData(keys: any[]) {
  const queryClient = useQueryClient();
  return queryClient.getQueryData(keys);
}
