import React, { useEffect, useRef } from 'react';
import { TableStyle } from './_style';
import { TableProps } from './_interface';
import { useRecoilState } from 'recoil';
import { themeState } from '../../store/Atoms';

const Table = ({ thead, tbody, caption, colgroup, height, tbodyHeight, type, tbodyId, width }: TableProps) => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const headRef = useRef<HTMLDivElement>(null);
  const [themeMode] = useRecoilState(themeState);

  //스크롤 영역이 실제 컨텐츠 영역보다 커질때 우측여백 추가

  useEffect(() => {
    //  const setScroll = () => {
    //    if (bodyRef.current && headRef.current) {
    //      if (Number(bodyRef.current.scrollHeight) > Number(bodyRef.current.offsetHeight)) {
    //        headRef.current.style.width = 'calc(100% - 54px)';
    //      } else {
    //        headRef.current.style.width = 'calc(100% - 48px)';
    //      }
    //    }
    //  };
    //  setScroll();
    //  window.addEventListener('resize', setScroll);
    //  return () => window.removeEventListener('resize', setScroll);
  });

  return (
    <TableStyle.Wrap height={height} width={width}>
      <TableStyle.Thead ref={headRef} type={type}>
        <table>
          <caption>{caption}</caption>
          <colgroup>
            {colgroup?.map((i, index) => {
              return <col key={index} style={{ width: i }} />;
            })}
          </colgroup>
          <thead>{thead}</thead>
        </table>
      </TableStyle.Thead>
      <TableStyle.Tbody className={themeMode === 'light' ? 'cScroll light' : 'cScroll'} ref={bodyRef} style={{ maxHeight: tbodyHeight }} type={type} id={tbodyId}>
        <table>
          <caption>{caption}</caption>
          <colgroup>
            {colgroup?.map((i, index) => {
              return <col key={index} style={{ width: i }} />;
            })}
          </colgroup>
          <tbody>{tbody}</tbody>
        </table>
      </TableStyle.Tbody>
    </TableStyle.Wrap>
  );
};

export default Table;
