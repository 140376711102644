import React, { useState, useEffect } from 'react';
import { LoginStyle, PwBoxSt } from './_style';
import useInputChange from 'hooks/useInputChange';
import { useNavigate } from 'react-router-dom';
import useMutateHook from 'hooks/reactQuery/useMutateHook';
import { fatchPwChange } from 'service/common';
import { userInfoState } from 'store/Atoms';
import { useRecoilValue } from 'recoil';

function PwChange(props) {
  const navigate = useNavigate();
  const userInfoValue = useRecoilValue(userInfoState);

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [form, setForm, onChange] = useInputChange({});

  const { mutate } = useMutateHook({ fetchApi: fatchPwChange });

  console.log(form);

  //   useEffect(() => {
  //     if (form?.password !== form?.password_confirm) {
  //       setError(true);
  //     } else {
  //       setError(false);
  //     }
  //   }, [form]);

  const isValidForm = () => {
    if (!form?.password) {
      console.log('새 비밀번호를 입력하세요.');
      return false;
    }
    if (!form?.password_confirm) {
      console.log('새 비밀번호 확인란을 입력하세요.');
      return false;
    }
    if (form?.password !== form?.password_confirm) {
      console.log('비밀번호가 일치하지 않습니다.');
      return false;
    }
    if (!userInfoValue.userId) {
      console.log('userId가 없습니다.');
      return false;
    }
    if (form?.password && form?.password_confirm) {
      if (form?.password === form?.password_confirm) {
        return true;
      }
    }
    return false;
  };

  const onClickConfirmBtn = async () => {
    // 유효성 검사
    if (isValidForm()) {
      await mutate(
        { userId: userInfoValue.userId, password: form?.password },
        {
          onSuccess(data, variables, context) {
            alert('비밀번호 변경이 완료되었습니다.');
            // 페이지 이동
            window.location.href = '/';
          },
          async onError(error: any, variables, context) {
            //  alert('비밀번호 변경에 실패하였습니다.');
            // console.log(error);
            // console.log(error?.data?.message);
            let status = error?.data?.status;
            let msg = error?.data?.message;
            await setErrorMsg(msg);
            setError(true);
          },
        },
      );
    }
  };

  return (
    <LoginStyle.Wrap>
      <PwBoxSt.Box>
        <PwBoxSt.Title>
          비밀번호 변경
          <PwBoxSt.Close
            onClick={() => {
              navigate(-1);
            }}
          ></PwBoxSt.Close>
        </PwBoxSt.Title>

        <PwBoxSt.LabelWrap>
          <span className="label">새 비밀번호</span>
          <LoginStyle.InputWrap className={'password'}>
            <LoginStyle.Input type={'password'} id={'password'} placeholder={'비밀번호를 입력하세요'} name={'password'} onChange={onChange} />
          </LoginStyle.InputWrap>
        </PwBoxSt.LabelWrap>

        <PwBoxSt.LabelWrap style={{ marginTop: '24px' }}>
          <span className="label">새 비밀번호 확인</span>
          <LoginStyle.InputWrap className={error ? 'password error' : 'password'}>
            <LoginStyle.Input type={'password'} id={'password_confirm'} placeholder={'비밀번호를 입력하세요'} name={'password_confirm'} onChange={onChange} />
          </LoginStyle.InputWrap>
        </PwBoxSt.LabelWrap>
        <LoginStyle.Msg className={error ? 'error' : undefined}>{errorMsg}</LoginStyle.Msg>

        <PwBoxSt.ConfirmBtn onClick={onClickConfirmBtn}>비밀번호 변경</PwBoxSt.ConfirmBtn>
      </PwBoxSt.Box>
    </LoginStyle.Wrap>
  );
}

export default PwChange;
