import React from 'react';
import styled from 'styled-components';
import IcoNoData from 'assets/images/popup/ico-noData.svg';

const NoDataSt = {
  Wrap: styled.div`
    width: 100%;
    height: 100%;
    border: 1px solid #d5d5d5;
  `,
  Cont: styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .ico {
      width: 40px;
      height: 40px;
      background: url(${IcoNoData}) no-repeat center;
    }
    .text {
      margin-top: 16px;
      font-weight: 500;
      font-size: 16px;
    }
  `,
};
function NoData() {
  return (
    <NoDataSt.Wrap>
      <NoDataSt.Cont>
        <div className="ico"></div>
        <p className="text">{'검색 결과가 없습니다.'}</p>
      </NoDataSt.Cont>
    </NoDataSt.Wrap>
  );
}

export default NoData;
