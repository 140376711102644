import React from 'react';
import { TextareaProps } from './_interface';
import styled from 'styled-components';

export const TextareaStyle = {
  Wrap: styled.div<TextareaProps>`
    width: ${(p) => (p.$width ? p.$width + 'px' : '100%')};
    height: ${(p) => (p.$height ? p.$height + 'px' : '40px')};
  `,
  Textarea: styled.textarea`
    width: 100%;
    height: 100%;
    padding: 11px 16px;
    box-sizing: border-box;
    resize: none;
    border: 1px solid #d5d5d5;
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
    &:focus {
      outline: none;
      border-color: #0076ff;
    }
    &::placeholder {
      color: #94949a;
      font-size: 14px;
    }
  `,
};

const Textarea = ({ $width, $height, id, name, onChange }: TextareaProps) => {
  return (
    <TextareaStyle.Wrap $width={$width} $height={$height}>
      <TextareaStyle.Textarea id={id} name={name} onChange={onChange} placeholder="입력해주세요"></TextareaStyle.Textarea>
    </TextareaStyle.Wrap>
  );
};

export default Textarea;
