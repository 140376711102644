import Table from 'components/atom/Table';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getDets, getMapData } from 'service/env';
import { detInfoState, overlayState, siteInfoState } from 'store/Atoms';

function DashboardTable({ setTabList }) {
  const [detInfo, setDetInfo] = useRecoilState(detInfoState); //감지장치
  const siteInfo = useRecoilValue(siteInfoState); // 현장
  const siteId = siteInfo?.siteId;

  const [overlayData, setOverlayData] = useRecoilState(overlayState); // 오버레이 오픈, 오버레이 리스트 정보

  // 지도 정보 조회
  const { data: mapData, isSuccess } = useQuery({
    queryKey: ['map', { siteId }],
    queryFn: () => getMapData(siteId, null),
    enabled: !!siteId,
  });
  const { siteDets: dets } = mapData || {};

  // GS 인증을 위한 데이터 로딩 시간 테스트
  useEffect(() => {
    async function getTableDataForGS() {
      console.time('TotalTime(ms)');

      const result = await getDets(siteId);
      console.log('Get_Table_Data:', result);

      console.timeEnd('TotalTime(ms)');
    }

    getTableDataForGS(); // 서버 데이터 요청
  }, [siteId]);

  // 첫번째 항목 기본 세팅
  useEffect(() => {
    if (isSuccess) {
      setDetInfo({
        ...dets[0],
        autoScroll: false, // 테이블 위치 자동 맞춤 기능
      });
    }
  }, [isSuccess, dets]);

  // scroll 자동 맞춤
  useEffect(() => {
    if (detInfo?.autoScroll) {
      if (isSuccess) {
        const activeIdx = dets?.findIndex((det) => detInfo?.detId === det.detId);

        const positionY = (activeIdx - 1) * 48;
        document.getElementById('detList')?.scrollTo(0, positionY);
      }
    }
  }, [detInfo, dets, isSuccess]);

  // 테이블 Tr 클릭 이벤트
  const onClickTr = (det) => {
    console.log(det);
    setDetInfo({
      ...det,
      autoScroll: false, // 테이블 위치 자동 맞춤 기능
    });
    // 오버레이 팝업 닫기
    setOverlayData((prev) => ({ ...prev, open: false }));
    // 탭 초기화
    setTabList((prev) => {
      return prev.map((ele, idx) => (idx === 0 ? { ...ele, active: true } : { ...ele, active: false }));
    });
  };

  return (
    <>
      <Table
        tbodyId="detList"
        caption={'감지장치 목록'}
        colgroup={['50%', 'auto']}
        //   height={'calc(100% - 138px)'}
        //   tbodyHeight={168}
        tbodyHeight={155}
        thead={
          <tr>
            <th scope={'col'}>장치</th>
            <th scope={'col'}>상태</th>
          </tr>
        }
        tbody={
          <>
            {dets?.map((det) => (
              <tr key={det.detId} id={` ${detInfo?.detId === det?.detId && 'activeTr'}`} className={`selectible ${detInfo?.detId === det?.detId && 'active'} ${det?.status !== 0 && 'error'}`} onClick={() => onClickTr(det)}>
                <td>{det?.detName}</td>
                <td>{det?.status === 0 ? '정상' : det?.status === 1 ? '비정상' : det?.status === 2 ? '신호없음' : ''}</td>
              </tr>
            ))}
          </>
        }
      />
    </>
  );
}

export default DashboardTable;
