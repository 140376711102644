import { useNavigate } from 'react-router-dom';
import Ico404 from 'assets/images/ico-404.svg';
import { NotFoundSt } from './_style';

function NotFound() {
  const navigate = useNavigate();
  const onClickBack = () => {
    navigate('/');
  };
  return (
    <NotFoundSt.Wrap>
      <NotFoundSt.ImgWrap>
        <img className="textImg" src={Ico404} alt="404" />
      </NotFoundSt.ImgWrap>
      <NotFoundSt.TextWrap>
        <p className="main">페이지를 찾을 수 없습니다.</p>
        <span className="sub">
          원하시는 결과를 찾을 수 없습니다.
          <br />
          올바른 URL을 입력하였는지 확인하세요.
        </span>
      </NotFoundSt.TextWrap>
      <NotFoundSt.BackBtn onClick={onClickBack}>메인페이지 돌아가기</NotFoundSt.BackBtn>
    </NotFoundSt.Wrap>
  );
}

export default NotFound;
