import styled from 'styled-components';
import IcoUpdate from 'assets/images/header/ico-update.svg';

export const HeaderSt = {
  Wrap: styled.div`
    width: 100%;
    min-width: 1366px;
    /* height: 72px; */
    height: 62px;
    padding: 0 40px;
    box-sizing: border-box;
    background-color: #f5f6fa;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
  `,
  TitleWrap: styled.div`
    cursor: pointer;
    .title {
      font-weight: 600;
      font-size: 28px;
    }
  `,
  UtilWrap: styled.div`
    display: flex;
    align-items: center;
    .updateTime {
      display: flex;
      align-items: center;
      gap: 8px;
      .icoUpdate {
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url(${IcoUpdate}) no-repeat center;
        cursor: pointer;
      }
      .text {
        font-weight: 500;
        font-size: 12px;
      }
      .countDown {
        width: 46px;
        text-align: right;
        margin-right: 20px;
      }
    }
  `,
  AlertWrap: styled.div`
    &.newAlert {
      > button {
        position: relative;
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: -3px;
          right: -3px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #f01a1a;
        }
      }
    }
  `,
  UserMenuWrap: styled.div`
    position: relative;
  `,
  UserMenu: styled.ul`
    min-width: 104px;
    display: flex;
    flex-direction: column;
    border: 1px solid #0076ff;
    border-radius: 2px;
    position: absolute;
    top: 40px;
    z-index: 11;
    right: 0;
    opacity: 0;
    transition: all 0.4s;
    box-shadow: 0px 4px 6px 0px #0076ff33;
    li {
      text-align: center;
      font-weight: 500;
      background-color: #fff;
      line-height: 0;
      display: none;
      cursor: pointer;
      &:hover {
        transition: all 0.4s;
        color: #0076ff;
        background-color: #d9eaff;
      }
    }
    &.isOpen {
      opacity: 1;
      top: 46px;
      li {
        line-height: 33px;
        display: block;
      }
    }
  `,
};
