import axios from 'axios';

export const request = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
    Authorization: sessionStorage.getItem('token'),
  },
});

// form-data 전송용 request
export const requestFormData = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: sessionStorage.getItem('token'),
  },
});

//request
// request.interceptors.request.use(
//   (config) => {
//     const token = sessionStorage.getItem('token');
//     console.log(token);

//     //  if (!token) {
//     //    window.location.href = '/login';
//     //    return config;
//     //  }
//     config.headers['Authorization'] = token;

//     return config;
//   },
//   (error) => {
//     console.log(error);
//     return Promise.reject(error);
//   },
// );

// response
request.interceptors.response.use(
  (response) => {
    return response;
  },
  ({ response }) => {
    if (response) {
      const { status, message } = response.data;
      if (401 === status) {
        // alert('권한이 없습니다. 로그인페이지로 이동합니다.');
        // request
        //   .post('/v1/refresh')
        //   .then(({ data }) => {
        //     sessionStorage.setItem('token', data.accessToken);
        //   })
        //   .catch((e) => {
        //     console.log();
        //   });
      }
    }
    return Promise.reject(response);
  },
);

// 로그인
export const fetchLogin = async (params: { email: string; password: string }) => {
  const { data } = await request.post(`/user/login`, params);

  return data.datas;
};

// 로그아웃
export const fetchLogout = () => {
  // session 삭제
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('userInfo');
  sessionStorage.removeItem('siteInfo');
  // 페이지 이동
  window.location.href = '/';
};

// 비밀번호 변경
export const fatchPwChange = async (params: { userId: string; password: string }) => {
  const { data } = await request.patch(`/user/password/${params.userId}`, null, { params: { password: params.password } });
  return data.datas;
};

// 비밀번호 초기화
export const postPwReset = async (params: { email: string }) => {
  const { data } = await request.post(`/user/password/init`, {}, { params: { ...params } });
  return data.datas;
};

// 인증(로그인) 이력
export const getLoginLog = async () => {
  const { data } = await request.get(`/user/login/log`);
  return data.datas;
};
