import IcoFile from 'assets/images/ico-file.svg';
import IcoFileDark from 'assets/images/ico-file-dark.svg';
import IcoPopClose from '../src/assets/images/ico-pop-close.svg';
import IcoPopCloseLight from '../src/assets/images/ico-pop-close-light.svg';
import IcoCheckbox from 'assets/images/ico-checkbox.svg';
import IcoCheckboxA from 'assets/images/ico-checkbox-active.svg';

export const light = {
  popup: {
    head: {
      bg: '#F1F4F9',
      title: '#242628',
      border: '#DFDFDF',
      icoClose: IcoPopCloseLight,
      btnHoverColor: 'rgba(144, 144, 144, 0.35)',
      btnActiveColor: 'rgba(144, 144, 144, 0.15)',
    },
    cont: { border: '#DFDFDF', bg: '#fff', color: '#242628' },
    foot: {
      border: '#DFDFDF',
      color: '#797A7C',
      bg: '#EEF0F8',
    },
  },
  notFound: {
    bg: '#EEF0F8',
    color: '#242628',
    bgImg: IcoFile,
  },
  checkbox: {
    ico: IcoCheckbox,
    activeIco: IcoCheckboxA,
  },
  select: {
    selected: {
      color: '#242628',
      border: '#D5D5D5',
      bg: '#fff',
      activeBg: '#fff',
      activeBorder: '#0076ff',
      labelBorder: 'transparent',
    },
    item: {
      color: '#242628',
      bgHover: 'rgba(0, 118, 255, 0.15)',
      colorHover: '#0076FF',
    },
  },
  button: {
    basicFullBlue: {
      color: '#fff',
      bg: '#4379EE',
      border: '#4379EE',
      hoverBg: '#6B96F3',
      hoverBorder: '#6B96F3',
    },
    basicToRed: { border: '#D5D5D5', hoverBorder: '#F01A1A' },
    basicToBlue: { border: '#D5D5D5', hoverBorder: '#0076FF' },
    greyLineToRed: {
      bg: '#F1F4F9',
      border: '#D5D5D5',
      color: '#242628',
      hoverBorder: '#F01A1A',
      hoverBg: 'rgba(240, 26, 26, 0.15)',
      hoverColor: '#f01a1a',
      hoverShadow: 'rgba(240, 26, 26, 0.2)',
    },
    greyLineToBlue: {
      bg: '#F1F4F9',
      border: '#D5D5D5',
      color: '#242628',
      hoverBorder: '#0076FF',
      hoverBg: 'rgba(0, 118, 255, 0.15)',
      hoverColor: '#0076FF',
      hoverShadow: 'rgba(0, 118, 255, 0.2)',
    },
  },
};

export const dark = {
  popup: {
    head: {
      bg: '#323D4E',
      title: '#fff',
      border: '#313D4F',
      icoClose: IcoPopClose,
      btnHoverColor: 'rgba(0, 118, 255, 0.25)',
      btnActiveColor: 'rgba(0, 118, 255, 0.15)',
    },
    cont: { border: '#313D4F', bg: '#273142', color: '#fff' },
    foot: {
      border: '#313D4F',
      color: '#A0AEC0',

      bg: '#273142',
    },
  },
  notFound: {
    bg: '#1C2431',
    color: '#fff',
    bgImg: IcoFileDark,
  },
  checkbox: {
    ico: IcoCheckbox,
    activeIco: IcoCheckboxA,
  },
  select: {
    selected: {
      color: '#242628',
      border: '#D5D5D5',
      bg: '#fff',
      activeBg: '#fff',
      activeBorder: '#0076ff',
      labelBorder: 'transparent',
    },
    item: {
      color: '#242628',
      bgHover: 'rgba(0, 118, 255, 0.15)',
      colorHover: '#0076FF',
    },
  },

  button: {
    basicFullBlue: {
      color: '#fff',
      bg: '#4379EE',
      border: '#4379EE',
      hoverBg: '#6B96F3',
      hoverBorder: '#6B96F3',
    },
    basicToRed: { border: '#D5D5D5', hoverBorder: '#F01A1A' },
    basicToBlue: { border: '#D5D5D5', hoverBorder: '#0076FF' },
    greyLineToRed: {
      bg: '#273142',
      border: '#4A576B',
      color: '#fff',
      hoverBorder: '#F01A1A',
      hoverBg: 'rgba(240, 26, 26, 0.15)',
      hoverColor: '#f01a1a',
      hoverShadow: 'rgba(240, 26, 26, 0.2)',
    },

    greyLineToBlue: {
      bg: '#fff',
      border: '#D5D5D5',
      color: '#242628',
      hoverBorder: '#0076FF',
      hoverBg: 'rgba(0, 118, 255, 0.15)',
      hoverColor: '#0076FF',
      hoverShadow: 'rgba(0, 118, 255, 0.2)',
    },
  },
};
