import { View, Map, Overlay, Feature } from 'ol';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import { XYZ, OSM } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { FitOptions } from 'ol/View';
import { Control, ScaleLine } from 'ol/control';
import { FullScreen, defaults as defaultControls } from 'ol/control.js';
import { baseProjection } from 'olMap/projection';

// h = roads only
// m = standard roadmap
// p = terrain
// r = somehow altered roadmap
// s = satellite only
// t = terrain only
// y = hybrid
//코어를 담당하는 mapInstance Viewer객체
//Viewr객체의 기능
class OlCore {
  public mapInstance: Map;
  public viewer: View;

  private SATELLITE_URL = 'http://mt0.google.com/vt/lyrs=r&hl=ko&x={x}&y={y}&z={z}';

  public googleMapSource = new XYZ({ url: this.SATELLITE_URL });
  public osmMapSource = new OSM();

  // 구글 위성 지도
  public googleMapLayer = new TileLayer({
    source: this.googleMapSource,
    properties: { name: 'google-map-satellite' },
    zIndex: 1,
    preload: Infinity,
  });

  // 기본 2D 지도
  public osmMapLayer = new TileLayer({
    source: this.osmMapSource,
    properties: { name: 'osm-map' },
    zIndex: 1,
    preload: Infinity,
  });

  // private selectMapSource: XYZ | OSM = this.googleMapSource
  //지도 초기화
  constructor() {
    // 해당 현장정보 -  지도 좌표계
    let projection = sessionStorage?.getItem('projection') || '';
    let siteProj = projection ? projection : baseProjection; // EPSG:4326

    this.mapInstance = new Map({
      controls: defaultControls().extend([new FullScreen()]),
      target: 'mapContainer',
      layers: [
        //   this.osmMapLayer,
        this.googleMapLayer,
      ],
      view: new View({
        zoom: 18, //초기 줌레벨 지정
        projection: siteProj,
      }),
    });
    this.viewer = this.mapInstance.getView();
    this.mapInstance.addControl(
      new ScaleLine({
        target: document.getElementById('scaleBar') as any,
      }),
    );
  }

  //해당 레이어로 화면이동
  public moveToLayer(layer?: VectorLayer<VectorSource>, option?: FitOptions) {
    if (layer?.getSource()?.getExtent()[0] !== Infinity) {
      layer && this.viewer.fit(layer.getSource()?.getExtent() || [], option);
    }
  }

  public getLayerFromName(layerId: string) {
    if (this.mapInstance.getAllLayers().filter((i) => i.get('id') === layerId).length) {
      return this.mapInstance.getAllLayers().filter((i) => i.get('id') === layerId)[0];
    }
  }

  public moveToFeature(feature?: Feature, option?: FitOptions) {
    if ((feature?.getGeometry() as any).getExtent() !== Infinity) {
      feature && this.viewer.fit(feature.getGeometry()?.getExtent() || [], option);
    }
  }
  public getMapInstance() {
    return this.mapInstance;
  }

  //지도 이동 후 스케일바 조절
  public onMoveEnd(callback: () => void) {
    this.mapInstance.on('moveend', () => {
      callback();
    });
  }

  public getOverlay(id: string) {
    return this.mapInstance
      .getOverlays()
      .getArray()
      .find((i: Overlay) => i.getOptions().id === id);
  }

  public mapChange(source: XYZ | OSM) {
    this.mapInstance.getAllLayers()[0].setSource(source);
  }
}

export default OlCore;
