import { useQueryState } from 'hooks/reactQuery/useQueryState';
import React from 'react';
import { getAlerts } from 'service/env';
import styled from 'styled-components';
import IcoAlert from 'assets/images/ico-alert.svg';
import IcoAlertA from 'assets/images/ico-alert-active.svg';
import IcoNoAlarm from 'assets/images/header/ico-noAlarm.svg';
import { DateObject } from 'react-multi-date-picker';

const PopupAlertListSt = {
  Wrap: styled.div<{ $width?: number; $height?: number }>`
    position: absolute;
    top: 61px;
    right: 40px;
    z-index: 10;
    width: ${(p) => (p.$width ? p.$width + 'px' : '400px')};
    height: ${(p) => (p.$height ? p.$height + 'px' : '600px')};
    background-color: #fff;
    border: 1px solid #dfdfdf;
    box-shadow: 4px 4px 10px 3px #0000000f;
  `,
  HeaderWrap: styled.div`
    height: 48px;
    padding: 0 12px 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dfdfdf;
    .title {
      color: ${(p) => p.theme.popup.head.title};
      font-weight: 600;
      font-size: 16px;
      text-align: center;
    }
    .closeBtn {
      width: 42px;
      height: 42px;
      background: url(${(p) => p.theme.popup.head.icoClose}) no-repeat center;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        transition: 0.4s;
        background-color: rgba(144, 144, 144, 0.35);
      }
    }
  `,
  Cont: styled.div`
    height: calc(100% - 48px);
    overflow: auto;
    > ul {
      width: 100%;
      > li {
        /* width: 100%; */
        height: 96px;
        padding: 0 24px;
        display: flex;
        align-items: center;
        .box {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #dfdfdf;
          .icoWrap {
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;
            background: #f7f7f7;
            border: 1px solid #797a7c;
            .ico {
              display: inline-block;
              width: 24px;
              height: 24px;
              background: url(${IcoAlert}) no-repeat center;
            }
            &.error {
              background: #fff3f3;
              border: 1px solid #f01a1a;
              .ico {
                background: url(${IcoAlertA}) no-repeat center;
              }
            }
          }
          .textWrap {
            margin-left: 20px;
            .comment {
              line-height: 19px;
              font-weight: 500;
              font-size: 16px;
            }
            .date {
              margin-top: 4px;
              line-height: 17px;
              color: #797a7c;
            }
          }
        }
        &:hover {
          /* background-color: #f4f7ff; */
        }
      }
    }
  `,
  NoData: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .ico {
      width: 40px;
      height: 40px;
      background: url(${IcoNoAlarm}) no-repeat center;
    }
    .text {
      margin-top: 16px;
    }
  `,
};
// 알림 리스트
function PopupAlertList(props) {
  const { $width, $height, close, alerts } = props;

  return (
    <PopupAlertListSt.Wrap $width={$width} $height={$height}>
      <PopupAlertListSt.HeaderWrap>
        <div className="title">알림</div>
        <div className="closeBtn" onClick={close}></div>
      </PopupAlertListSt.HeaderWrap>
      <PopupAlertListSt.Cont>
        {alerts?.length > 0 ? (
          <ul>
            {alerts?.map((ele, idx) => (
              <li key={'alerts' + idx}>
                <div className="box">
                  <div className={`icoWrap error`}>
                    <span className="ico"></span>
                  </div>
                  <div className="textWrap">
                    <p className="comment">{ele?.content || '-'}</p>
                    <p className="date">{new Date(ele?.createdDate)?.toLocaleString()}</p>
                    {/* <p className="date">{new DateObject(ele?.createdDate)?.format('YYYY-MM-DD hh:mm:ss', ['Date', 'Time'])}</p> */}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <PopupAlertListSt.NoData>
            <div className="ico"></div>
            <div className="text">새로운 알림이 없습니다.</div>
          </PopupAlertListSt.NoData>
        )}
      </PopupAlertListSt.Cont>
    </PopupAlertListSt.Wrap>
  );
}

export default PopupAlertList;
