import React, { useState, useEffect } from 'react';
import { FullScreen } from 'ol/control.js';
import OlCore from 'olMap/OlCore';
import InitPositionBtn from 'components/atom/InitPositionBtn';

import ObjectPipeLine from 'olMap/Object/ObjectPipeLine';
import ObjectPoint from 'olMap/Object/ObjectPoint';
import ClusterOverlay from '../map/ClusterOverlay';
import MapLegend from 'components/molecule/MapLegend';
// json data
import mapData from 'olMap/data/map.data.json';
import { getMapData } from 'service/env';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { detInfoState, siteInfoState, overlayState } from 'store/Atoms';
import { useQuery } from '@tanstack/react-query';

function DashboardMap() {
  const { siteId } = useRecoilValue(siteInfoState);
  const [detInfo, setDetInfo] = useRecoilState(detInfoState);
  const [overlayData, setOverlayData] = useRecoilState(overlayState); // 오버레이 오픈, 오버레이 리스트 정보

  const [isObjectReady, setIsObjectReady] = useState(false);
  const [mapInstance, setMapInstance] = useState<OlCore>();
  const [point, setPoint] = useState<ObjectPoint>();
  const [line, setLine] = useState<ObjectPipeLine>();

  // 지도 정보 조회
  const mapData = useQuery({
    queryKey: ['map', { siteId }],
    queryFn: () => getMapData(siteId, null),
    enabled: !!siteId,
  });

  //Openlayers 초기화
  useEffect(() => {
    if (!document.querySelector('.ol-viewport')) {
      const core = new OlCore();
      const point = new ObjectPoint(core);
      const line = new ObjectPipeLine(core);

      // ** FullScreen 컨트롤 삭제
      core.mapInstance.getControls().forEach(function (control) {
        if (control instanceof FullScreen) {
          core.mapInstance.removeControl(control);
        }
      });

      setMapInstance(core); // core
      setPoint(point); // point
      setLine(line); // pipeLine
      setIsObjectReady(true);
    }
  }, []);

  useEffect(() => {
    if (isObjectReady) {
      if (mapData?.data) {
        //   console.log(mapData?.data);
        // [포인트]
        point?.draw(mapData?.data); // 포인트 그리기
        point?.addSelectClick(setOverlayData); // 포인트 클릭
        // point?.addMapMoveEvent();

        // point?.addMapClickEvent();
        // point?.clusterSelect(setOverlayState, setOverlayList);

        // [라인]
        // line?.addMapMoveEvent(); // 라인 hover 스타일
        // line?.drawTest(); // geojson 데이터 테스트
        line?.draw(mapData?.data); // 파이프 그리기
        line?.addLineSelectClick({
          setOverlayData,
        }); // 파이프 클릭
        line?.addMapClickEvent(); // 지도 클릭 이벤트
      } else {
        //데이터 없을때
        //   console.log(mapData?.data);
      }
    }
  }, [isObjectReady, mapData?.data]);

  // 테이블 클릭 - 감지장치 활성화 (dynamic)
  useEffect(() => {
    if (isObjectReady && mapData?.data) {
      // console.log('detInfo', detInfo);
      let targetCaseId = detInfo?.caseId;
      let targetFeature = point?.getFeatureById({ caseId: targetCaseId });
      if (targetFeature) {
        point?.resetSelect(); // 기존 셀렉트 초기화
        //   point?.dynamicSelect(targetFeature); // 해당하는 감지장치 포인트 활성화
      }
    }
  }, [isObjectReady, mapData?.data, detInfo, point]);

  // 테이블 클릭 - 파이프 활성화 (dynamic)
  useEffect(() => {
    if (isObjectReady && mapData?.data) {
      let targetPipeNum;
      mapData?.data?.cases?.map((ele) => {
        ele?.pipes?.map((pipe) => {
          // 스마트 파이프만 det배열 존재
          if (pipe?.isSmartPipe === 1) {
            // 선택된 detId가 있는 파이프인지 체크
            let det = pipe?.smartPipeDets?.find((det) => det.detId === detInfo.detId);
            if (det) targetPipeNum = pipe.pipeNum; // 해당 pipeNum
          }
        });
      });

      let targetFeature = line?.getFeatureById({ pipeNum: targetPipeNum });
      if (targetFeature) {
        line?.resetSelect(); // 기존 셀렉트 초기화
        line?.dynamicSelect(targetFeature); // 해당하는 파이프 활성화
      }
    }
  }, [isObjectReady, mapData?.data, detInfo, line]);

  return (
    <div id={'mapContainer'} style={{ position: 'relative', width: '100%', height: `calc(100% - 52px)` }}>
      {/* 범례 */}
      <MapLegend />

      {/* 초기 위치 이동 버튼  */}
      {/* <InitPositionBtn /> */}
    </div>
  );
}

export default DashboardMap;
