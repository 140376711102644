import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import SwiperArea from 'components/atom/SwiperArea';
import { useQueryState } from 'hooks/reactQuery/useQueryState';
import { useRecoilValue } from 'recoil';
import { getDetDetail } from 'service/env';
import { detInfoState, siteInfoState } from 'store/Atoms';
import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';

export const DetailSt = {
  Wrap: styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
  `,
  SlideWrap: styled.div`
    padding: 28px 24px;
    box-sizing: border-box;
    width: 100%;
    height: 456px;
    height: 390px;
    height: calc(100% - 320px);
    .imgBg {
      width: 530px;
      padding: 12px 0;
      box-sizing: border-box;
      border-radius: 4px;
      background-color: #f6fbff;
      display: flex;
      justify-content: center;
    }
  `,
  DetailWrap: styled.div`
    margin-top: 38px;
    padding: 0 24px;
    .box {
      padding: 14px 0;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
      display: flex;
      height: 100%;
      box-sizing: border-box;
      > ul {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 16px;
        > li {
          line-height: 17px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          > .title {
            font-weight: 600;
          }
        }
      }
    }
  `,
  Divider: styled.div`
    width: 1px;
    height: 100%;
    background-color: #e0e0e0;
    margin: 0 24px;
  `,
};

//사진 및 상세정보 탭
function DashboardDetail() {
  const detInfo = useRecoilValue(detInfoState); // 감지장치
  const detId = detInfo?.detId;

  //감지장치 상세정보 조회
  const { data: detDetail, refetch } = useQueryState(['det', 'detail', { detId }], () => getDetDetail(detId), { enabled: !!detId });

  return (
    <>
      <DetailSt.Wrap>
        <DetailSt.SlideWrap>
          <SwiperArea initialSlide={0} perView={1}>
            {/* children */}
            {detDetail?.imgs?.length > 0 ? (
              detDetail.imgs.map((img, idx) => (
                <SwiperSlide key={`img-${idx}`}>
                  <div className="imgBg">
                    <img src={img} alt="" />
                  </div>
                </SwiperSlide>
              ))
            ) : (
              <SwiperSlide>
                <div className="imgBg">{/* <span>이미지가 없습니다</span> */}</div>
              </SwiperSlide>
            )}
          </SwiperArea>
        </DetailSt.SlideWrap>

        {/* 하단 상세 정보 */}
        <DetailSt.DetailWrap>
          <div className="box">
            <ul>
              <li>
                <span className="title">시공일자</span>
                <span>{detDetail?.constDate || '-'}</span>
              </li>
              <li>
                <span className="title">위도</span>
                <span>{detDetail?.point?.y || '-'}</span>
              </li>
              <li>
                <span className="title">경도</span>
                <span>{detDetail?.point?.x || '-'}</span>
              </li>
            </ul>
            {/* <DetailSt.Divider></DetailSt.Divider>
            <ul>
              <li>
                <span className="title">번호</span>
                <span>7</span>
              </li>
              <li>
                <span className="title">번호</span>
                <span>7</span>
              </li>
              <li>
                <span className="title">번호</span>
                <span>7</span>
              </li>
              <li>
                <span className="title">번호</span>
                <span>7</span>
              </li>
            </ul> */}
          </div>
        </DetailSt.DetailWrap>
      </DetailSt.Wrap>
    </>
  );
}

//
export default React.memo(DashboardDetail);
