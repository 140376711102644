import React from 'react';
import PopupFrameA from './PopupFrame_A';
import styled from 'styled-components';

const ContSt = {
  Cont: styled.div`
    padding: 4px 0;
    box-sizing: border-box;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: break-spaces;
  `,
};

// alert 작은 사이즈
function PopupAlert({ title, text, close, leftFunc }) {
  return (
    <PopupFrameA $width={240} title={title} leftText="확인" close={close} leftFunc={leftFunc}>
      <ContSt.Cont>{text}</ContSt.Cont>
    </PopupFrameA>
  );
}

export default PopupAlert;
