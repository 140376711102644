import React, { useEffect, useState } from 'react';
import TabArea from 'components/molecule/TabArea';
import DashboardDetail from './dashboard/DashboardDetail';
import DashboardChart from './dashboard/DashboardChart';
import DashboardAction from './dashboard/DashboardAction';

function DashboardInfoCont({ tabList, setTabList }) {
  const getComponent = () => {
    return tabList.map((ele) => {
      if (ele.active) {
        switch (ele.text) {
          case '정보':
            return <DashboardDetail key={'Detail'} />;
          case '조치내역':
            return <DashboardAction key={'Action'} />;
          case '센서 차트':
            return <DashboardChart key={'Chart'} />;
        }
      }
    });
  };

  return (
    <>
      {/* 탭 영역 */}
      <TabArea list={tabList} setTabList={setTabList} />
      {getComponent()}
    </>
  );
}

export default DashboardInfoCont;
