import React, { ReactElement, ReactNode } from 'react';
import { SwiperStyle } from './_style';
import { Swiper, SwiperSlide } from 'swiper/react';

//
import 'swiper/css';
import 'swiper/css/pagination';
// import 'swiper/css/navigation';
// import 'swiper/css/thumbs';

import { Pagination } from 'swiper/modules';
import { Navigation } from 'swiper/modules';

// PopupSwiper
const SwiperArea = ({ children, perView, perGroup, initialSlide }: any) => {
  return (
    <>
      <SwiperStyle.Wrap>
        <Swiper
          initialSlide={initialSlide} // 시작 슬라이드 번호 0 ~
          slidesPerGroup={perGroup ? perGroup : 1}
          spaceBetween={11}
          slidesPerView={perView ? perView : 1}
          onSlideChange={() => console.log('slide change')}
          //  onSwiper={(swiper) => console.log(swiper)}
          navigation={{
            enabled: true,
            nextEl: '#btnSwiperNext',
            prevEl: '#btnSwiperPrev',
          }}
          pagination={{
            el: '#swiper-pagination', // 기본 스타일 대신 사용할 요소
            type: 'bullets', // fraction, bullets ,..
          }}
          modules={[Navigation, Pagination]}
        >
          {children}
        </Swiper>
        <SwiperStyle.Navi>
          <button type={'button'} id={'btnSwiperPrev'} className={'btnSwiperPrev'}></button>
          <button type={'button'} id={'btnSwiperNext'} className={'btnSwiperNext'}></button>
        </SwiperStyle.Navi>
        <SwiperStyle.Footer>
          <div id={'swiper-pagination'}></div>
        </SwiperStyle.Footer>
      </SwiperStyle.Wrap>
    </>
  );
};

export default SwiperArea;
