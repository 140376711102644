import React, { useEffect, useState } from 'react';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import { loaderState } from 'store/Atoms';
import { useRecoilState } from 'recoil';

const useLoading = () => {
  const [loaderInfo, setLoaderInfo] = useRecoilState(loaderState);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //현재 로드되지 않은 api 갯수 확인(로딩적용)
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  //   console.log(isFetching, isMutating);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isFetching + isMutating !== 0) {
        // local state 방식 (global하게 state 변경이 어려움)
        setIsLoading(true);
        // recoil 방식
        setLoaderInfo({ show: true });
      } else {
        setIsLoading(false); // local  state 방식
        setLoaderInfo({ show: false }); // recoil 방식
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [isFetching, isMutating]);

  return [isLoading, setIsLoading] as const;
};

export default useLoading;
