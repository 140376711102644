import styled from 'styled-components';
import { ButtonProps, CheckboxProps, InputProps, PaginationStyleProps, SelectProps, TableProps } from './_interface';
import IcoNaviPrev from 'assets/images/ico-navi-prev.svg';
import IcoNaviNext from 'assets/images/ico-navi-next.svg';
import IcoPageNext from 'assets/images/pagination/ico-page-next.svg';
import IcoPagePrev from 'assets/images/pagination/ico-page-prev.svg';
import IcoPageNextLast from 'assets/images/pagination/ico-page-next-last.svg';
import IcoSelectDown from 'assets/images/ico-select-down.svg';

const CheckboxDefaultStyle = {
  Wrap: styled.div`
    position: relative;
    min-height: 24px;
    overflow: hidden;
    display: flex;
    align-items: center;
  `,
  Label: styled.label<CheckboxProps>`
    display: flex;
    align-items: center;
    font-size: 14px;

    &:before {
      cursor: pointer;
      content: '';
      width: ${(p) => (p.size ? p.size + 'px' : '24px')};
      height: ${(p) => (p.size ? p.size + 'px' : '24px')};
      background-image: ${(p) => `url(${p.theme.checkbox?.ico})`};

      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      transition: 0.4s;
      box-sizing: border-box;
      margin-right: ${(p) => (p.text ? '10px' : 0)};
    }
  `,
  Input: styled.input`
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    font-size: 0;
    width: 0;
    height: 0;
  `,
};

export const CheckboxStyle = {
  ...CheckboxDefaultStyle,
  Input: styled(CheckboxDefaultStyle.Input)`
    &.dark {
      &:checked + ${CheckboxDefaultStyle.Label} {
        &:before {
          background-image: ${(p) => `url(${p.theme.checkbox.activeIco})`};
        }
      }
    }
    &:checked + ${CheckboxDefaultStyle.Label} {
      &:before {
        background-image: ${(p) => `url(${p.theme.checkbox.activeIco})`};
      }
    }
  `,
};

export const ButtonStyleDefault = {
  Wrap: styled.button<ButtonProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border-radius: ${(p) => (p.$radius ? p.$radius + 'px' : '2px')};
    width: ${(p) => (p.$width === 'full' ? '100%' : typeof Number(p.$width) === 'number' ? p.$width + 'px' : 'auto')};
    height: ${(p) => (p.$height ? p.$height + 'px' : '40px')};
    padding: 0 12px;
    box-sizing: border-box;
    transition: 0.4s;
    background: ${(p) => (p.colorType ? p.theme.button[`${p.colorType}`].bg : '#F5F6FA')};
    border-width: 1px;
    border-style: solid;
    border-color: ${(p) => (p.colorType ? p.theme.button[`${p.colorType}`].border : '#D5D5D5')};

    &:before,
    &:after {
      content: '';
      transition: 0.4s;
      width: ${(p) => (p.icoSize ? p.icoSize + 'px' : '24px')};
      height: ${(p) => (p.icoSize ? p.icoSize + 'px' : '24px')};
      background-position: center;
      background-repeat: no-repeat;
      background-image: ${(p) => (p.ico ? 'url(' + p.ico + ')' : 'none')};
    }

    &:before {
      display: ${(p) => (p.ico && p.icoPosition === 'left' ? 'block' : 'none')};
      /* margin-right: 10px; */
    }

    &:after {
      display: ${(p) => (p.ico && p.icoPosition === 'right' ? 'block' : 'none')};
      /* margin-left: 10px; */
    }

    &.active,
    &:hover {
      border-color: ${(p) => (p.colorType ? p.theme.button[`${p.colorType}`].hoverBorder : '#0076FF')};
      background: ${(p) => (p.colorType ? p.theme.button[`${p.colorType}`].hoverBg : 'transparent')};
      box-shadow: ${(p) => (p.colorType ? `0 4px 4px 0  ${p.theme.button[`${p.colorType}`].hoverShadow}` : 'none')};

      &:before,
      &:after {
        background-image: ${(p) => (p.icoHover ? 'url(' + p.icoHover + ')' : 'url(' + p.ico + ')')};
      }
    }

    &:disabled {
      background: #ccc;
    }
  `,
  Text: styled.span<ButtonProps>`
    font-size: ${(p) => (p.$fontSize ? p.$fontSize + 'px' : '14px')};
    transition: 0.4s;
    font-weight: ${(p) => (p.$fontWeight ? p.$fontWeight : 400)};
    color: ${(p) => (p.colorType ? p.theme.button[`${p.colorType}`].color : '#242628')};
  `,
};

export const ButtonStyle = {
  ...ButtonStyleDefault,
  Wrap: styled(ButtonStyleDefault.Wrap)`
    &.active,
    &:hover {
      ${ButtonStyleDefault.Text} {
        color: ${(p) => (p.colorType ? p.theme.button[`${p.colorType}`].hoverColor : '#242628')};
      }
    }

    &:disabled {
      border: 0;
      ${ButtonStyleDefault.Text} {
        color: #fff;
      }
    }
  `,
};

// table
export const TableStyle = {
  Wrap: styled.div<TableProps>`
    width: ${(p) => (p.width ? p.width + 'px' : '100%')};
    height: ${(p) => (p.height ? p.height : 'auto')};
  `,
  Thead: styled.div<TableProps>`
    position: ${(p) => (p.height ? 'relative' : 'sticky')};
    top: ${(p) => (p.height ? 'auto' : 0)};
    width: calc(100% - 48px);
    margin: 0 auto;

    &:after {
      position: absolute;
      right: -24px;
      top: 48px;
      content: '';
      /* width: calc(100% + 56px); */
      width: calc(100% + 48px);
      height: 1px;
      background-color: #e0e0e0;
    }

    table {
      width: 100%;
      box-sizing: border-box;

      th {
        box-sizing: border-box;
        height: 48px;
        font-size: 14px;
        font-weight: 600;
      }
    }
  `,
  Tbody: styled.div<TableProps>`
    width: calc(100% - 48px);
    margin: 16px auto 0;

    table {
      width: 100%; //border-collapse:separate;
      border-collapse: separate;
      border-spacing: 0 2px;
      tr {
        box-sizing: border-box;
        &.selectible {
          cursor: pointer;
          &:hover {
            td {
              background: rgba(72, 128, 255, 0.15);
              color: #4880ff;
            }
            &.error {
              td {
                background: #fddddd;
              }
            }
          }
        }
        &.active {
          td {
            background: rgba(72, 128, 255, 0.15);
            color: #4880ff;
          }
          &.error {
            td {
              background: #fddddd;
            }
          }
        }
        &.error {
          td {
            color: #f01a1a;
          }
        }

        &:first-child {
          td {
            border-top: 0;
          }
        }

        td {
          box-sizing: border-box;
          height: 48px;
          background-color: #f5f5f9;
          text-align: center;
          font-weight: 600;
          font-size: 14px;
          transition: 0.4s;
        }
      }
    }
  `,
};

// paging table
export const PagingTableStyle = {
  Wrap: styled.div<TableProps>`
    width: ${(p) => (p.width ? p.width + 'px' : '100%')};
    height: ${(p) => (p.height ? p.height : 'auto')};
  `,
  Thead: styled.div<TableProps>`
    position: ${(p) => (p.height ? 'relative' : 'sticky')};
    top: ${(p) => (p.height ? 'auto' : 0)};
    width: 100%;
    margin: 0 auto;

    table {
      height: 48px;
      width: calc(100% - 2px);
      margin: 0 auto;

      background-color: #fafbff;
      /* border-collapse: collapse; */
      border-collapse: separate;
      border-spacing: 0 8px;
      border-radius: 4px 4px 0 0;
      border-style: hidden;
      box-shadow: 0 0 0 1px #d5d5d5;
      tr {
        th {
          font-size: 14px;
          font-weight: 600;
          border-left: 1px solid #d5d5d5;
          &:first-child {
            border-left: 0;
          }
        }
      }
    }
  `,
  Tbody: styled.div<TableProps>`
    width: 100%;
    border: 1px solid #d5d5d5;
    box-sizing: border-box;
    /* width: calc(100% - 48px); */
    /* margin: 16px auto 0; */
    table {
      width: 100%;

      tr {
        height: 64px;
        border-bottom: 1px solid #d5d5d5;
        box-sizing: border-box;
        &:last-child {
          border-bottom: 0;
        }
        &:hover,
        &.active {
          td {
          }
        }

        td {
          text-align: center;
          font-size: 14px;
          transition: 0.4s;
          /* td 사이 경계선 */
          position: relative;
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: -1px;
            transform: translate(0, -50%);
            height: 49px;
            width: 1px;
            background-color: #d5d5d5;
          }
        }
      }
    }
  `,
};

// swiper
export const SwiperStyle = {
  Wrap: styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    .swiper {
      height: 100%;
      .swiper-wrapper {
        height: 100%;
      }
    }
    .swiper-slide {
      /* border: 1px solid red;
      box-sizing: border-box; */
      display: flex;
      justify-content: center;
      /* 이미지 사이즈 */
      img {
        width: auto;
        height: 100%;
        border-radius: 4px;
      }
    }
  `,
  Navi: styled.div`
    width: 100%;
    /* padding: 0 24px; */
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btnSwiperPrev,
    .btnSwiperNext {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-position: center;
      &.swiper-button-disabled {
        opacity: 0.5;
      }
    }
    .btnSwiperPrev {
      background-image: url(${IcoNaviPrev});
      &:hover {
      }
    }
    .btnSwiperNext {
      background-image: url(${IcoNaviNext});
      &:hover {
      }
    }
  `,
  Footer: styled.div`
    width: 100%;
    margin-top: 5px;
    #swiper-pagination {
      margin: 0 auto;
      width: fit-content;
      .swiper-pagination-bullet {
        width: 20px;
        height: 4px;
        border-radius: 2px;
        background-color: #bbc4d4;
        &.swiper-pagination-bullet-active {
          background-color: #0076ff;
        }
      }
    }
  `,
};

export const PaginationStyle = {
  Wrap: styled.div`
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  First: styled.button<PaginationStyleProps>`
    width: ${(p) => (p.btnSize ? p.btnSize + 'px' : '40px')};
    height: ${(p) => (p.btnSize ? p.btnSize + 'px' : '40px')};
    font-size: 12px;
    font-weight: 200;
    color: #a0a5b1;

    transform: rotate(180deg);
    background: url(${IcoPageNextLast}) no-repeat center;

    /* border: 1px solid #ebebeb; */
  `,
  Prev: styled.button<PaginationStyleProps>`
    width: ${(p) => (p.btnSize ? p.btnSize + 'px' : '40px')};
    height: ${(p) => (p.btnSize ? p.btnSize + 'px' : '40px')};
    background: url(${IcoPageNext}) no-repeat center;
    transform: rotate(180deg);

    /* border: 1px solid #ebebeb; */
    border-radius: 4px;
    margin-right: 10px;
    transition: all.3s;
    &:hover {
    }
  `,
  Next: styled.button<PaginationStyleProps>`
    width: ${(p) => (p.btnSize ? p.btnSize + 'px' : '40px')};
    height: ${(p) => (p.btnSize ? p.btnSize + 'px' : '40px')};
    background: url(${IcoPageNext}) no-repeat center;

    /* border: 1px solid #ebebeb; */
    border-radius: 4px;
    margin-left: 10px;
    transition: all.3s;
    &:hover {
    }
  `,
  Last: styled.button<PaginationStyleProps>`
    width: ${(p) => (p.btnSize ? p.btnSize + 'px' : '40px')};
    height: ${(p) => (p.btnSize ? p.btnSize + 'px' : '40px')};
    /* font-size: 12px; */
    /* font-weight: 200; */
    /* color: #a0a5b1; */

    /* border: 1px solid #ebebeb; */
    background: url(${IcoPageNextLast}) no-repeat center;
  `,
  NumGroup: styled.div`
    display: flex;
    gap: 8px;
  `,
  Num: styled.button<PaginationStyleProps>`
    min-width: ${(p) => (p.btnSize ? p.btnSize + 'px' : '40px')};
    height: ${(p) => (p.btnSize ? p.btnSize + 'px' : '40px')};
    line-height: ${(p) => (p.btnSize ? p.btnSize + 'px' : '40px')};
    padding: 0 4px;
    font-size: 14px;
    font-weight: 200;
    color: #7e7e7e;
    /* border: 1px solid #ebebeb; */
    box-sizing: border-box;
    &.active {
      border-color: #fafafa;
      background: #fafafa;
      color: #000;
      font-weight: 600;
      border-radius: 2px;
    }
  `,
  Ellipsis: styled.span`
    color: #a3a3a3;
    font-size: 14px;
    font-weight: 200;
    width: 24px;
    height: 24px;
    text-align: center;
    vertical-align: bottom;
  `,
};

export const SelectStyle = {
  Area: styled.div`
    display: flex;
    align-items: center;
  `,
  Wrap: styled.div<SelectProps>`
    position: relative;
    width: ${(p) => (p.width === 'full' ? '100%' : typeof Number(p.width) === 'number' ? p.width + 'px' : 'auto')};
  `,
  Selected: styled.strong<SelectProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    box-sizing: border-box;
    height: ${(p) => (p.height ? p.height + 'px' : '40px')};
    border: 1px solid ${(p) => p.theme.select.selected.border};
    color: ${(p) => p.theme.select.selected.color};
    background: ${(p) => p.theme.select.selected.bg} url(${IcoSelectDown}) no-repeat right 16px center;
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
    padding: 0 11px 0 16px;
    transition: 0.4s;

    &.active {
      background: ${(p) => p.theme.select.selected.activeBg} url(${IcoSelectDown}) no-repeat right 16px center;
      border: 1px solid #0076ff;

      + div {
        display: block;
        z-index: 100;
      }
    }

    &.disabled {
      background-color: #ececec;
    }

    &:focus-visible {
      outline: none;
    }

    &:hover {
      background-color: rgba(72, 128, 255, 0.15);
      border: 1px solid #313d4f;
    }
  `,
  Label: styled.div<{ height?: number }>`
    display: flex;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    border: 1px solid ${(p) => p.theme.select.selected.border};
    border-right-color: ${(p) => p.theme.select.selected.labelBorder};
    height: ${(p) => (p.height ? p.height + 'px' : '40px')};
    background: ${(p) => p.theme.select.selected.bg};
    color: #767676;
    font-size: 14px;
    font-weight: 500;
  `,
  ListWrap: styled.div`
    position: absolute;
    z-index: 10;
    left: 0;
    top: calc(100% + 4px);
    display: none;
    width: 100%;
    border: 1px solid ${(p) => p.theme.select.selected.activeBorder};
    background: ${(p) => p.theme.select.selected.bg};
    box-sizing: border-box;
    box-shadow: 0 4px 6px 0 rgba(0, 118, 255, 0.2);
  `,
  Search: styled.div`
    padding: 16px 16px 10px;

    input {
      background: #fff no-repeat right 20px center;
      margin: 0 auto;
      border: 1px solid #e2e2e2 !important;
    }
  `,
  List: styled.ul`
    max-height: 200px;
    overflow-y: auto;
  `,
  Item: styled.li<SelectProps>`
    height: ${(p) => (p.height ? p.height + 'px' : '33px')};
    line-height: ${(p) => (p.height ? p.height + 'px' : '33px')};
    font-size: 14px;
    font-weight: 500;
    padding: 0 16px;
    cursor: pointer;
    color: ${(p) => p.theme.select.item.color};

    &:hover,
    &:focus {
      background: ${(p) => p.theme.select.item.bgHover};
      color: ${(p) => p.theme.select.item.colorHover};
      outline: none;
    }
  `,
};

export const InputStyle = {
  Wrap: styled.div<InputProps>`
    position: relative;
    display: flex;
    align-items: center;
    width: ${(p) => (p.$width === 'full' ? '100%' : typeof p.$width === 'number' ? p.$width + 'px' : 'auto')};
    height: ${(p) => (p.$height ? p.$height + 'px' : '40px')};
    > .inputTooltip {
      position: absolute;
      top: ${(p) => (p.$height ? p.$height + 'px' : '40px')};
      left: 0;
      background-color: #5c5c5cad;
      color: #fff;
      padding: 4px 4px;
      border-radius: 2px;
    }
  `,
  Input: styled.input<InputProps>`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #d5d5d5;
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
    margin-right: ${(p) => (p.component ? '10px' : 0)};
    flex: ${(p) => (p.component ? 1 : 'none')};
    padding: 0 16px;
    transition: 0.4s;
    /* 
    background-repeat: no-repeat;
    background-position: 16px center;
    background-image: ${(p) => (p.leftIco ? `url(${p.leftIco})` : '')}; */

    &[type='number'] {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
      }
    }

    &::placeholder {
      padding: ${(p) => (p.leftIco ? '0 28px' : '')};
      color: #94949a;

      // 아이콘
      background-repeat: no-repeat;
      background-position: 0px center;
      background-image: ${(p) => (p.leftIco ? `url(${p.leftIco})` : '')};
    }

    &:focus {
      outline: none;
      border: 1px solid #0076ff;
    }

    &:disabled {
      background: #ececec;
    }

    ${(p) => p.error && 'outline:1px solid #dc5700'}
  `,
  ErrMsg: styled.p`
    position: absolute;
    left: 0;
    top: calc(100% + 6px);
    font-size: 14px;
    color: #dc5700;
    font-weight: 200;
  `,
};
