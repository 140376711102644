import React, { useState } from 'react';

import styled from 'styled-components';
import PopupFrameA from './PopupFrame_A';
import PopupAlert from './PopupAlert';

const ContSt = {
  Cont: styled.div`
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
  `,
};

function PopupContact({ close }) {
  const [openAlert, setOpenAlert] = useState(false);
  // 클립보드에 복사
  const onClickCopy = async () => {
    let text = document.getElementById('copyTarget')?.innerText || '';
    console.log(text);

    try {
      await navigator.clipboard.writeText(text);
      // alert('클립보드에 주소가 복사되었습니다.');
      setOpenAlert(true);
    } catch (e) {
      alert('복사에 실패하였습니다');
    }
  };

  const closeAlert = () => {
    close();
    setOpenAlert(false);
  };
  return (
    <>
      <PopupFrameA title="문의하기" $width={260} close={close} leftText="닫기" rightText="E-mail 복사" leftFunc={close} rightFunc={onClickCopy}>
        <ContSt.Cont>
          <div id="copyTarget">support@movements.kr</div>
        </ContSt.Cont>
      </PopupFrameA>

      {/* 결과 알림창 */}
      {openAlert && <PopupAlert title={''} text={'클립보드에 주소가 \n 복사되었습니다.'} close={closeAlert} leftFunc={closeAlert} />}
    </>
  );
}

export default PopupContact;
