import Header from 'components/organism/Header';
import React from 'react';
import { Outlet } from 'react-router-dom';

function Container(props) {
  return (
    <div id="wrap">
      <Header />
      <div id="container">
        {/* router */}
        <Outlet />
      </div>
    </div>
  );
}

export default Container;
