import React from 'react';
import { ButtonStyle } from './_style';
import { ButtonProps } from './_interface';

const Button = ({ $width, $height, colorType, $fontSize, $fontWeight, text, onClick, style, ico, icoHover, icoPosition, icoSize, disabled, $radius, active }: ButtonProps) => {
  return (
    <ButtonStyle.Wrap
      className={active ? 'active' : ''}
      type={'button'}
      $width={$width}
      $height={$height}
      colorType={colorType}
      disabled={disabled}
      $radius={$radius}
      onClick={onClick}
      style={style}
      ico={ico}
      icoHover={icoHover}
      icoPosition={icoPosition}
      icoSize={icoSize}
    >
      {text && (
        <ButtonStyle.Text $fontSize={$fontSize} $fontWeight={$fontWeight} colorType={colorType}>
          {text}
        </ButtonStyle.Text>
      )}
    </ButtonStyle.Wrap>
  );
};

export default Button;
