import React, { useState } from 'react';
import PopupFrame from './PopupFrame';
import styled from 'styled-components';
import Checkbox from 'components/atom/Checkbox';
import DatePickerInput from 'components/atom/DatePickerInput';
import { clearScreenDown } from 'readline';
import Textarea from 'components/atom/Textarea';
import Select from 'components/atom/Select';
import useInputChange from 'hooks/useInputChange';
import { channel } from 'diagnostics_channel';

export const PopupAddActionSt = {
  ContWrap: styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    .checkboxWrap {
      display: flex;
      align-items: center;
      gap: 36px;
    }
  `,
};

export const LabelForm = {
  Wrap: styled.div<{ $align?: 'top' }>`
    display: flex;
    align-items: ${(p) => (p.$align ? p.$align : 'center')};
  `,
  Label: styled.p`
    width: 100px;
    flex: 1 0 100px;
    color: #797a7c;
  `,
  Item: styled.div`
    font-weight: 500;
    flex: 1 1 100%;
  `,
};

function PopupAddAction({ close }) {
  const [radioOption, setRadioOption] = useState([
    { text: '배터리', value: 'battery', checked: true },
    { text: '신호 이상', value: 'channel', checked: false },
  ]);
  const [form, setForm, onChange] = useInputChange({ actionDate: null, actionType: 'battery', channel: null, actionText: '' });

  console.log(form);

  // form 유효성 검사
  const isValid = () => {
    if (true) {
      return true;
    }
    return false;
  };

  // 날짜 선택
  const onChangeDatePicker = (date, name) => {
    console.log(date, name);
    setForm((prev) => ({ ...prev, actionDate: date }));
  };

  // 라디오 버튼
  const onChangeRadio = (e) => {
    const { value } = e.target;
    setRadioOption((prev) => prev.map((ele) => (ele.value === value ? { ...ele, checked: true } : { ...ele, checked: false })));

    // 배터리 선택시 : channel 초기화
    if (value === 'battery') {
      setForm((prev) => ({ ...prev, actionType: value, channel: null }));
    } else {
      // 신호 이상(channel) 선택시
      setForm((prev) => ({ ...prev, actionType: value }));
    }
  };

  // select onChange 이벤트
  const onChangeSelect = (e, name) => {
    const { ariaValueText, innerText } = e.currentTarget;
    console.log(ariaValueText, innerText);

    setForm((prev) => ({ ...prev, channel: ariaValueText }));
  };

  return (
    <>
      <PopupFrame $width={434} title="조치 내역 추가" close={close} leftText="취소" rightText="등록" leftFunc={close}>
        <PopupAddActionSt.ContWrap>
          <LabelForm.Wrap>
            <LabelForm.Label>발생장치</LabelForm.Label>
            <LabelForm.Item>
              <p>감지장치 #01</p>
            </LabelForm.Item>
          </LabelForm.Wrap>

          <LabelForm.Wrap>
            <LabelForm.Label>조치일</LabelForm.Label>
            <LabelForm.Item>
              <DatePickerInput name={'actionDate'} onCustomChange={onChangeDatePicker} />
            </LabelForm.Item>
          </LabelForm.Wrap>

          <LabelForm.Wrap>
            <LabelForm.Label>이상 내용</LabelForm.Label>
            <LabelForm.Item>
              <div className="checkboxWrap">
                {radioOption.map((ele, idx) => (
                  <Checkbox key={`ck-${idx}`} type="radio" id={`#radio${idx}`} name="#key" value={ele.value} text={ele.text} size={18} onChange={onChangeRadio} defaultChecked={ele.checked} />
                ))}
              </div>
            </LabelForm.Item>
          </LabelForm.Wrap>

          {radioOption.find((ele) => ele.text === '신호 이상')?.checked && (
            <LabelForm.Wrap>
              <LabelForm.Label>채널</LabelForm.Label>
              <LabelForm.Item>
                <Select
                  placeHolder="채널 선택"
                  option={[
                    { active: false, value: 'channel_1', text: 'channel_1' },
                    { active: false, value: 'channel_2', text: 'channel_2' },
                    { active: false, value: 'channel_3', text: 'channel_3' },
                  ]}
                  onChange={onChangeSelect}
                />
              </LabelForm.Item>
            </LabelForm.Wrap>
          )}

          <LabelForm.Wrap $align="top">
            <LabelForm.Label style={{ paddingTop: '11px' }}>내역</LabelForm.Label>
            <LabelForm.Item>
              <Textarea $height={180} name="actionText" onChange={onChange} />
            </LabelForm.Item>
          </LabelForm.Wrap>
        </PopupAddActionSt.ContWrap>
      </PopupFrame>
    </>
  );
}

export default PopupAddAction;
