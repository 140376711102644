import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

export const TabAreaSt = {
  Wrap: styled.div`
    padding: 0 24px;
    box-sizing: border-box;
    border-bottom: 1px solid #dfdfdf;
    height: 56px;
    display: flex;
    align-items: flex-end;
    gap: 28px;
  `,
  Item: styled.div`
    height: 37px;
    color: #767676;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    &.active {
      color: #0076ff;
      border-bottom: 2px solid #0076ff;
      box-sizing: border-box;
    }
    &:hover {
      transition: all.4s;
      color: #5aa7ff;
    }
  `,
};

function TabArea({ list, setTabList }: { list: { id: string; text: string; active: boolean }[]; setTabList: React.Dispatch<any> }) {
  // 탭 선택시, 해당 데이터의 active 값 업데이트!
  const onClickTab = (id) => {
    setTabList((prev) => {
      return prev.map((ele) => (ele.id === id ? { ...ele, active: true } : { ...ele, active: false }));
    });
  };

  return (
    <TabAreaSt.Wrap>
      {list?.map((ele) => (
        <TabAreaSt.Item key={'tab' + ele?.id} className={ele.active ? 'active' : ''} onClick={() => onClickTab(ele?.id)}>
          {ele?.text}
        </TabAreaSt.Item>
      ))}
    </TabAreaSt.Wrap>
  );
}

export default React.memo(TabArea);
