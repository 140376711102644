import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.scss';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App from './App';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
// import reportWebVitals from "./reportWebVitals";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      gcTime: 0,
      // staleTime: 0,
      // gcTime: 1000 * 60 * 5,
      // gcTime: Infinity,
    },
  },
  queryCache: new QueryCache({
    onError(error: any, query) {
      let status = error?.status;
      // 권한 없음
      if (status === 401) {
        sessionStorage.clear();
        window.location.href = '/';
      }
    },
  }),
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <QueryClientProvider client={queryClient}>
    {/* react-query Devtools */}
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    {/* <RouterProvider router={routers} /> */}
    <RecoilRoot>
      <RecoilNexus />
      <App />
    </RecoilRoot>
  </QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
