import React, { useState, useEffect, useCallback } from 'react';
import PopupFrame from './PopupFrame';
import { PopupSiteChangeSt } from './_style';
import { getDateDiff, isKoreanOnly, maxLengthCheck } from 'util/global';
import Input from 'components/atom/Input';
import Button from 'components/atom/Button';
import useInputChange from 'hooks/useInputChange';
import IcoSearch from 'assets/images/ico-input-search.svg';
import NoData from 'components/atom/NoData';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { siteInfoState } from 'store/Atoms';
import { useQueryClient } from '@tanstack/react-query';

// 현장 변경 팝업
// siteId, sites 전달받아 사용
// -> 데이터 무결성 : header에서(한곳에서) 요청할때만 상태 업데이트
function PopupSiteChange({ close, siteId, sites }) {
  const queryClient = useQueryClient();
  const setSiteInfo = useSetRecoilState(siteInfoState);

  const [form, setForm, onChange] = useInputChange({});

  const [selSiteId, setSelSiteId] = useState(siteId); // 목록에서 선택한 현장(임시저장)
  const [siteList, setSiteList] = useState<any[]>([]); // 목록에 보여줄 현장 리스트

  const [inputError, setInputError] = useState(false);

  useEffect(() => {
    if (sites?.length > 0) {
      setSiteList(sites);
    }
  }, [sites]);

  // 조회 버튼 클릭
  const onClickSearch = useCallback(() => {
    console.log(form?.siteName);
    //  setInputError(false);

    //  //2~10글자 이상 (한글)
    //  if (!form?.siteName) {
    //    console.log('검색어를 입력하세요.');
    //  }
    if (form?.siteName?.length < 2) {
      console.log('검색어를 2글자 이상 입력하세요.');
      return;
    }

    // 1자 이상 입력됐을때 체크
    if (form?.siteName?.length > 0) {
      let isKorean = isKoreanOnly(form?.siteName);
      if (!isKorean) {
        console.log('한글을 입력하세요.');
        //   setInputError(true);
      }
    }

    // sites 데이터 내에서 filter
    let results = sites.filter((site) => site.siteName.includes(form?.siteName || ''));
    //str.replaceAll(' ', '');
    //  console.log(results);
    setSiteList(results);
  }, [form, sites]);

  // Enter <-> 조회 이벤트 연결
  useEffect(() => {
    const keyDownEvent = (evt) => {
      if (evt.key === 'Enter') {
        onClickSearch();
      }
    };
    document.addEventListener('keydown', keyDownEvent);
    return () => {
      document.removeEventListener('keydown', keyDownEvent);
    };
  }, [onClickSearch]);

  // 검색창 비웠을 때 전체 목록 조회 되도록 처리.
  useEffect(() => {
    if (String(form?.siteName)?.length === 0) {
      setSiteList(sites);
    }
  }, [form?.siteName, sites]);

  // 목록 선택 이벤트
  const onClickRow = (id) => {
    setSelSiteId(id);
  };

  // 변경 버튼 이벤트
  const myRightFunc = () => {
    setSiteInfo((prev) => ({
      ...prev,
      siteId: selSiteId,
    }));

    window.location.reload(); // 지도 화면도 날리기 위해서
    //  queryClient.resetQueries();

    close();
  };

  return (
    <PopupFrame $width={744} title="현장 변경" leftText="취소" rightText="변경" close={close} leftFunc={close} rightFunc={myRightFunc}>
      <PopupSiteChangeSt.SearchWrap>
        <Input
          $width={646}
          $height={40}
          id="siteName"
          name="siteName"
          onChange={onChange}
          placeholder="현장명으로 검색"
          leftIco={IcoSearch}
          maxLength={10}
          component={<Button colorType="basicFullBlue" $width={100} text="조회" onClick={onClickSearch} disabled={String(form?.siteName)?.length < 2 || !form?.siteName} />}
          tooltipTxt="한글만 입력하세요."
          error={inputError}
        />
      </PopupSiteChangeSt.SearchWrap>
      <PopupSiteChangeSt.Box>
        {siteList.length > 0 ? (
          <PopupSiteChangeSt.List className="cScroll">
            {siteList?.map((ele) => (
              <PopupSiteChangeSt.Item
                key={ele?.siteId}
                className={`${ele.siteId === selSiteId && 'active'} ${ele.status !== 0 && 'error'}`}
                //   className={ele.siteId === selSiteId ? 'active' : ''}
                onClick={() => onClickRow(ele.siteId)}
              >
                <span className="ico"></span>
                <div className="siteWrap">
                  <p className="siteName">{ele?.siteName || '-'}</p>
                  <p className="siteAddr">{ele?.siteAddr || '-'}</p>
                </div>
                <div className="detailWrap">
                  <p className="openDateWrap">
                    <span className="subTitle">시공 기간</span>
                    <span className="openDate">
                      {ele?.startDate} ~ {ele?.endDate}
                      {/* ({getDateDiff(new Date(), ele?.openDate)}) */}
                    </span>
                  </p>
                  <p className="openDateWrap">
                    <span className="subTitle">시스템 가동일자</span>
                    <span className="openDate">
                      {ele?.openDate || '-'}
                      {/* ({getDateDiff(new Date(), ele?.openDate)}) */}
                    </span>
                  </p>
                </div>
              </PopupSiteChangeSt.Item>
            ))}
          </PopupSiteChangeSt.List>
        ) : (
          <NoData />
        )}
      </PopupSiteChangeSt.Box>
    </PopupFrame>
  );
}

export default PopupSiteChange;
