import React from 'react';
import PopupFrame from './PopupFrame';
import styled from 'styled-components';
import PopupFrameA from './PopupFrame_A';

const PopupVersionSt = {
  Cont: styled.div`
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  `,
};

function PopupVersion({ close }) {
  return (
    <>
      <PopupFrameA title="버전 정보" $width={248} close={close}>
        <PopupVersionSt.Cont>
          <div>스마트 파이프 v1.0</div>
          {/* <div>스마트 파이프 WEB</div> */}
        </PopupVersionSt.Cont>
      </PopupFrameA>
    </>
  );
}

export default PopupVersion;
