import styled from 'styled-components';
import IcoInfo from 'assets/images/ico-information.svg';
import IcoSitePos from 'assets/images/siteHeader/ico-site-position.svg';
import IcoSitePosE from 'assets/images/siteHeader/ico-site-position-error.svg';

export const PopupFrameSt = {
  Dimmed: styled.div`
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: rgba(0, 0, 0, 0.5);
  `,
  Popup: styled.div<{ $width?: number }>`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    width: ${(p) => (p.$width ? p.$width + 'px' : '528px')};
    /* min-height: 387px; */
  `,
  HeaderWrap: styled.div`
    height: 48px;
    background-color: ${(p) => p.theme.popup.head.bg};
    border: 1px solid ${(p) => p.theme.popup.head.border};
    border-bottom: 0;
    border-radius: 4px 4px 0 0;
    position: relative;
    .title {
      color: ${(p) => p.theme.popup.head.title};
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .closeBtn {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      width: 42px;
      height: 42px;
      background: url(${(p) => p.theme.popup.head.icoClose}) no-repeat center;
      border-radius: 4px;
      &:hover {
        transition: 0.4s;
        background-color: rgba(144, 144, 144, 0.35);
      }
    }
  `,
  ContWrap: styled.div`
    padding: 20px 24px;
    background-color: ${(p) => p.theme.popup.cont.bg};
    border: 1px solid ${(p) => p.theme.popup.cont.border};
  `,
  Footer: styled.div`
    padding: 16px 24px;
    background-color: ${(p) => p.theme.popup.cont.bg};
    border: 1px solid ${(p) => p.theme.popup.cont.border};
    border-top: 0;
    border-radius: 0 0 4px 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.full {
      padding: 0;
    }
  `,
  BtnWrap: styled.div`
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    button {
      min-width: 112px;
      padding: 12px 24px;
      box-sizing: border-box;
      color: ${(p) => p.theme.button.greyLineToBlue.color};
      background-color: ${(p) => p.theme.button.greyLineToBlue.bg};
      border: 1px solid ${(p) => p.theme.button.greyLineToBlue.border};
      font-weight: 400;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        transition: 0.4s;
        color: ${(p) => p.theme.button.greyLineToBlue.hoverColor};
        background-color: ${(p) => p.theme.button.greyLineToBlue.hoverBg};
        border-color: ${(p) => p.theme.button.greyLineToBlue.hoverBorder};
        box-shadow: 0px 4px 4px 0px ${(p) => p.theme.button.greyLineToBlue.hoverShadow};
      }
      &.red {
        &:hover {
          color: ${(p) => p.theme.button.greyLineToRed.hoverColor};
          background-color: ${(p) => p.theme.button.greyLineToRed.hoverBg};
          border-color: ${(p) => p.theme.button.greyLineToRed.hoverBorder};
          box-shadow: 0px 4px 4px 0px ${(p) => p.theme.button.greyLineToRed.hoverShadow};
        }
      }
    }
    &.full {
      margin-left: 0;
      width: 100%;
      padding: 0;
      button {
        width: 100%;
        border: 0;
        border-radius: 0 0 4px 4px;
        background-color: #b8b8b833;
        &:hover {
          transition: 0.4s;
          color: ${(p) => p.theme.button.greyLineToBlue.hoverColor};
          background-color: ${(p) => p.theme.button.greyLineToBlue.hoverBg};
          border-color: ${(p) => p.theme.button.greyLineToBlue.hoverBorder};
          box-shadow: none;
        }
      }
    }
  `,
};

export const PopupFrameASt = {
  ...PopupFrameSt,
  HeaderWrap: styled(PopupFrameSt.HeaderWrap)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    box-sizing: border-box;
    .title {
      text-align: left;
      position: static;
      transform: none;
    }
  `,
  ContWrap: styled(PopupFrameSt.ContWrap)`
    padding: 32px 0;
    border-bottom-width: 0px;
    &.noFooter {
      border-radius: 0 0 4px 4px;
      border-bottom-width: 1px;
    }
    &.noTitle {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  `,
  Footer: styled(PopupFrameSt.Footer)`
    padding: 0;
  `,
  BtnWrap: styled(PopupFrameSt.BtnWrap)`
    width: 100%;
    gap: 0;
    button {
      width: 50%;
      border: 0;
      font-weight: 500;
      &:nth-child(2) {
        border-left: 1px solid #dfdfdf;
      }
    }
  `,
};

export const PopupSiteChangeSt = {
  SearchWrap: styled.div`
    margin-bottom: 10px;
    height: 72px;
    background-color: #f1f4f9;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 26px;
  `,
  Box: styled.div`
    height: 575px;
    height: 60vh;
  `,
  List: styled.ul`
    height: 100%;
    border: 1px solid #d5d5d5;
  `,
  Item: styled.li`
    height: 68px;
    padding: 0 24px 0 12px;
    box-sizing: border-box;
    border-bottom: 1px solid #d5d5d5;
    display: flex;
    align-items: center;
    cursor: pointer;
    &.active {
      background-color: #e4ecff;
    }
    &:hover {
      transition: all 0.4s;
      background-color: #f4f7ff;
    }
    &:last-child {
      border-bottom-width: 0px;
    }
    &:first-child {
      border-bottom-width: 1px;
    }
    > .ico {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(${IcoSitePos}) no-repeat center;
    }
    > .siteWrap {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      > .siteName {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }
      > .siteAddr {
        color: #777;
        line-height: 18.06px;
      }
    }
    > .detailWrap {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      align-items: end;
      gap: 7px;
      > p {
        /* line-height: 16.8px;
        height: 16.8px; */
      }
      > .openDateWrap {
        > .subTitle {
          padding-right: 8px;
          color: #777;
          line-height: 14px;
          border-right: 1px solid #dfdfdf;
        }
        > .openDate {
          margin-left: 8px;
          font-weight: 500;
        }
      }
    }
    &.error {
      > .ico {
        background: url(${IcoSitePosE}) no-repeat center;
      }
      .siteName {
        color: #f01a1a;
      }
    }
  `,
};
