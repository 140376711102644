import React, { useState } from 'react';
import styled from 'styled-components';
import PagingTable from 'components/atom/PagingTable';
import Button from 'components/atom/Button';
import PopupAddAction from 'popups/PopupAddAction';

export const ActionSt = {
  Wrap: styled.div`
    height: 100%;
    width: 100%;
    padding: 12px 24px;
    box-sizing: border-box;
  `,
  BtnWrap: styled.div`
    display: flex;
    padding: 0 0 10px 0;
    justify-content: flex-end;
  `,
};

function DashboardFix(props) {
  const [param, setParam] = useState({ pageSize: 4, pageNo: 0 });
  const [openPop, setOpenPop] = useState(false); // 조치내역 추가 팝업 오픈
  return (
    <>
      <ActionSt.Wrap>
        {/* 조치내역 추가 버튼 */}
        <ActionSt.BtnWrap>
          <Button colorType="basicFullBlue" text="추가" $height={40} $width={100} $fontWeight={600} onClick={() => setOpenPop(true)} />
        </ActionSt.BtnWrap>

        {/* 조치내역 테이블 */}
        <PagingTable
          totalPages={20}
          param={param}
          setParam={setParam}
          btnSize={36}
          caption={'감지장치 목록'}
          colgroup={['15%', '20%', 'auto', '17%']}
          //   height={'calc(100% - 138px)'}
          //   tbodyHeight={455}
          height={'calc(100% - 230px)'}
          tbodyHeight={'calc(100% - 50px)'}
          thead={
            <tr>
              <th scope={'col'}>채널</th>
              <th scope={'col'}>이벤트</th>
              <th scope={'col'}>내용</th>
              <th scope={'col'}>조치일</th>
            </tr>
          }
          tbody={
            <>
              <tr>
                <td>CH1</td>
                <td>신호 발생</td>
                <td>배터리 교체 채널 이상 점검 완료 배터리 교체 채널</td>
                <td>2024/05/08</td>
              </tr>
              <tr>
                <td>CH1</td>
                <td>신호 발생</td>
                <td>배터리 교체 채널 이상 점검 완료</td>
                <td>2024/05/08</td>
              </tr>
              <tr>
                <td>CH1</td>
                <td>신호 발생</td>
                <td>배터리 교체 채널 이상 점검 완료</td>
                <td>2024/05/08</td>
              </tr>
              <tr>
                <td>CH1</td>
                <td>신호 발생</td>
                <td>배터리 교체 채널 이상 점검 완료</td>
                <td>2024/05/08</td>
              </tr>
              <tr>
                <td>CH1</td>
                <td>신호 발생</td>
                <td>배터리 교체 채널 이상 점검 완료</td>
                <td>2024/05/08</td>
              </tr>
              <tr>
                <td>CH1</td>
                <td>신호 발생</td>
                <td>배터리 교체 채널 이상 점검 완료</td>
                <td>2024/05/08</td>
              </tr>
              <tr>
                <td>CH1</td>
                <td>신호 발생</td>
                <td>배터리 교체 채널 이상 점검 완료</td>
                <td>2024/05/08</td>
              </tr>
              <tr>
                <td>CH1</td>
                <td>신호 발생</td>
                <td>배터리 교체 채널 이상 점검 완료</td>
                <td>2024/05/08</td>
              </tr>
            </>
          }
        />
      </ActionSt.Wrap>
      {/* 조치내역 추가 팝업 */}
      {openPop && <PopupAddAction close={() => setOpenPop(false)} />}
    </>
  );
}

export default DashboardFix;
