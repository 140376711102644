/* 
   환경부용 API 모음
   /env
*/

import { request } from './common';

// 현장 목록 조회
export const getSites = async () => {
  const { data } = await request.get(`/env/sites`);
  return data.datas;
};

// 지도 조회 (파이프, 함체 데이터 등)
export const getMapData = async (siteId, increaseIdx) => {
  const { data } = await request.get(`/env/sites/${siteId}/map`, { params: { increaseIdx: increaseIdx } });
  return data.datas;
};

// 현장 내 감지장치 목록 조회
export const getDets = async (siteId) => {
  const { data } = await request.get(`/env/sites/${siteId}/dets`);
  return data.datas;
};

// 감지장치 상세정보 조회
export const getDetDetail = async (detId) => {
  const { data } = await request.get(`/env/dets/${detId}`);
  return data.datas;
};

// 감지장치 센서차트 조회
export const getDetChart = async ({ detId, beforeDays, opticalInterval }) => {
  const { data } = await request.get(`/env/dets/${detId}/chart`, { params: { beforeDays, opticalInterval } });
  return data.datas;
};

// 이상 데이터 조회(감지장치)
export const getDetAnomaly = async ({ detId, source }) => {
  const { data } = await request.get(`/env/dets/${detId}/anomaly`, { params: {}, cancelToken: source.token });
  return data.datas;
};

// 알림 - 읽지않은 알람 리스트 조회
export const getAlerts = async () => {
  const { data } = await request.get(`/env/alert`);
  return data.datas;
};

// 알림 - 읽음 처리 (header -> body로 파라미터 전송 방법 변경)
export const patchAlerts = async (alertIds) => {
  //   const { data } = await request.patch(`/env/alert`, null, {
  //     params: { alertIds },
  //     paramsSerializer: {
  //       indexes: null,
  //     },
  //   });
  const { data } = await request.patch(`/env/alert`, alertIds);
  return data.datas;
};
