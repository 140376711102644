import { DateObject } from 'react-multi-date-picker';

//'YYYY.MM.DD' 형태
export const getDateString = (date) => {
  return new DateObject(date)?.format('YYYY.MM.DD');
};

// 두 날짜 "일" 차이 구하기
export const getDateDiff = (d1, d2) => {
  const date1 = new Date(d1);
  const date2 = new Date(d2);

  const diffDate = date1.getTime() - date2.getTime();

  const result = Math.floor(diffDate / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일 (Math.abs 무조건 양수)
  return result >= 0 ? `${result}일` : '0일'; // -일 일때는 0일로 표시중
};

// "M월" 형태
export function MonthsForLocale(locale: 'en' | 'ko' = 'ko', monthFormat: 'long' | 'short' | 'numeric' = 'short') {
  const { format } = new Intl.DateTimeFormat(locale, { month: monthFormat });

  // return [...Array(12).keys()].map((m) => format(new Date(Date.UTC(2021, m % 12))));
  return [...Array(12).keys()].map((m) => format(new Date(2021, m)));
}

// 한글만 입력되었는지 체크
export function isKoreanOnly(text) {
  // 한글만 포함된 정규식 패턴 (가-힣은 한글 음절의 범위)
  const pattern = /^[가-힣]+$/;
  return pattern.test(text);
}

// 특수 문자 체크
function checkSpecial(str) {
  const regExp = /[!?@#$%^&*():;+-=~{}<>\_\[\]\|\\\"\'\,\.\/\`\₩]/g;
  if (regExp.test(str)) {
    return true;
  } else {
    return false;
  }
}

// 한글 체크
function checkKor(str) {
  const regExp = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g;
  if (regExp.test(str)) {
    return true;
  } else {
    return false;
  }
}

// 숫자 체크
function checkNum(str) {
  const regExp = /[0-9]/g;
  if (regExp.test(str)) {
    return true;
  } else {
    return false;
  }
}

// 영문(영어) 체크
function checkEng(str) {
  const regExp = /[a-zA-Z]/g; // 영어
  if (regExp.test(str)) {
    return true;
  } else {
    return false;
  }
}
// 공백(스페이스 바) 체크
function checkSpace(str) {
  if (str.search(/\s/) !== -1) {
    return true; // 스페이스가 있는 경우
  } else {
    return false; // 스페이스 없는 경우
  }
}

// type = number 일 때 maxLength 속성 적용 안됨
// 해결 -> onInput 이벤트 이용해서 value 변경처리
export function maxLengthCheckNumber(e: React.ChangeEvent<HTMLInputElement>) {
  const { value, type, maxLength } = e.target;

  let targetType = type;
  let targetMaxLength = maxLength;

  if (targetType === 'number') {
    if (value.length > targetMaxLength) {
      e.target.value = value.slice(0, targetMaxLength);
    }
  }
}

// input 글자수 제한 (onInput이나 onKeyUp 이벤트 등에서 사용)
// export function maxLengthCheck(e: React.KeyboardEventHandler<HTMLInputElement> ) {
export function maxLengthCheck(e: any) {
  const { value, type, maxLength } = e.target as HTMLInputElement;

  if (value.length > maxLength) {
    e.target.value = value.slice(0, maxLength);
  }
}

/* 최소값 구하기 함수 */
// 배열을 직접 순회하면서 최소값을 찾습니다. 이 방법은 스택 문제를 피할 수 있습니다.
export function findMin(arr) {
  let min = Infinity;

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] < min) {
      min = arr[i];
    }
  }

  return min;
}

// reduce 함수를 사용하여 배열을 한 번 순회하면서 최소값을 계산합니다.
export function findMinWithReduce(arr) {
  return arr.reduce((min, value) => Math.min(min, value), Infinity);
}

export function findMaxWithReduce(arr) {
  return arr.reduce((max, value) => Math.max(max, value), 0);
}
