import React from 'react';
import styled from 'styled-components';

const MapLegendSt = {
  Wrap: styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 9;
    height: 52px;
    background-color: rgba(255, 255, 255, 0.88);
    border-radius: 2px;
    border: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    padding: 0 24px;
    box-sizing: border-box;
  `,
  List: styled.ul`
    display: felx;
    align-items: center;
    gap: 28px;
    li {
    }
  `,
  Item: styled.li<{ $color?: string }>`
    display: felx;
    align-items: center;
    gap: 8px;

    font-weight: 500;
    .ico {
      display: block;
      width: 12px;
      height: 12px;

      background-color: ${(p) => (p.$color ? p.$color : 'transparent')};
      border: ${(p) => (p.$color ? '' : '1px solid #000')};
      border-radius: 50%;
    }
  `,
};
function MapLegend() {
  return (
    <>
      <MapLegendSt.Wrap>
        <MapLegendSt.List>
          <MapLegendSt.Item>
            <span className="ico"></span>함체
          </MapLegendSt.Item>
          <MapLegendSt.Item $color="#7caeea">
            <span className="ico"></span>스마트 파이프
          </MapLegendSt.Item>
          <MapLegendSt.Item $color="#868585">
            <span className="ico"></span>일반 파이프
          </MapLegendSt.Item>
          {/* <MapLegendSt.Item $color="#F01A1A">
            <span className="ico"></span>이상 발생
          </MapLegendSt.Item> */}
          <MapLegendSt.Item $color="#0076FF">
            <span className="ico"></span>선택
          </MapLegendSt.Item>
        </MapLegendSt.List>
      </MapLegendSt.Wrap>
    </>
  );
}

export default MapLegend;
