import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { detInfoState } from 'store/Atoms';
import styled from 'styled-components';
// import IcoSite from 'assets/images/ico-point.svg';
// import IcoSiteArrow from 'assets/images/ico-point-arrow.svg';

const ClusterOverlayStyle = {
  Wrap: styled.div`
    display: none;
    position: relative;
    width: 192px;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    &.active {
      display: block;
    }
  `,
  Head: styled.div`
    /* height: 44px;
    border-bottom: 1px solid #00b5b5;
    background: #e7f8f8;
    padding: 0 14px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center; */
  `,
  Title: styled.strong`
    font-family: 'Pretendard';
    font-size: 16px;
    font-weight: 600;
    color: #000;
  `,
  Close: styled.button`
    position: relative;
    width: 20px;
    height: 20px;
    &:before,
    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      content: '';
      width: 21px;
      height: 1px;
      background: #767676;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  `,
  Cont: styled.div`
    background: #fff;
    max-height: 200px;
  `,
  Inner: styled.div`
    padding: 10px 5px;
    height: calc(100% - 8px);
  `,
  List: styled.ul`
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
  `,
  Item: styled.li`
    height: 44px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    transition: 0.3s;
    background-color: #f5f5f9;
    border-radius: 2px;
    cursor: pointer;
    span {
      font-size: 14px;
      font-weight: 500;
      font-family: 'Pretendard';
      color: #242628;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    /* &:before {
      content: '';
      width: 24px;
      height: 24px;
      margin-right: 6px;
    } */
    /* border-bottom: 1px solid #e5e5e5; */
    /* border-top: 1px solid transparent; */
    &.active,
    &:hover {
      /* border-top: 1px solid #31a6a6; */
      /* border-bottom: 1px solid #31a6a6; */
      box-shadow: 0px 4px 6px 0px rgba(0, 118, 255, 0.2);
      background: #e4ecff;
      span {
        color: #0076ff;
      }
      &.error {
        box-shadow: 0px 4px 6px 0px rgba(240, 26, 26, 0.2);
        background: #fddddd;
      }
    }
    &.error {
      span {
        color: #f01a1a;
      }
    }
  `,
};

const ClusterOverlay = ({ open, overlayList, setTabList }) =>
  // { mapInstance, list,  setOverlayStatus, objectSite, setPopState, setSiteData }
  {
    const [detInfo, setDetInfo] = useRecoilState(detInfoState);

    // scroll 자동 맞춤
    useEffect(() => {
      const activeIdx = overlayList?.findIndex((det) => detInfo?.detId === det.detId);

      const positionY = (activeIdx - 1) * 44;
      document.getElementById('detListOverlay')?.scrollTo(0, positionY);
    }, [detInfo, overlayList]);

    const onClickItem = (ele) => {
      setDetInfo({
        ...ele,
        autoScroll: true,
      });
      // 탭 초기화
      setTabList((prev) => {
        return prev.map((ele, idx) => (idx === 0 ? { ...ele, active: true } : { ...ele, active: false }));
      });
    };
    //  console.log(overlayList);

    return (
      <div id={'clusterOverlay'}>
        <ClusterOverlayStyle.Wrap className={open ? 'active' : undefined}>
          <ClusterOverlayStyle.Head>
            <ClusterOverlayStyle.Title></ClusterOverlayStyle.Title>
          </ClusterOverlayStyle.Head>
          <ClusterOverlayStyle.Cont className={'cScroll'} id="detListOverlay">
            <ClusterOverlayStyle.Inner>
              <ClusterOverlayStyle.List>
                {overlayList?.map((ele) => {
                  return (
                    <ClusterOverlayStyle.Item key={ele?.detId} className={`${detInfo?.detId === ele?.detId && 'active'} ${ele?.status !== 0 && 'error'}`} onClick={() => onClickItem(ele)}>
                      <span>{ele?.detName}</span>
                      <span>{ele?.status === 0 ? '정상' : ele?.status === 1 ? '비정상' : ele?.status === 2 ? '신호없음' : ''}</span>
                    </ClusterOverlayStyle.Item>
                  );
                })}
              </ClusterOverlayStyle.List>
            </ClusterOverlayStyle.Inner>
          </ClusterOverlayStyle.Cont>
        </ClusterOverlayStyle.Wrap>
      </div>
    );
  };

export default ClusterOverlay;
