import VectorModule from './VectorModule';
import { Feature } from 'ol';
import { Point } from 'ol/geom';

class PointModule extends VectorModule {
  // 단일 피쳐 생성 (속성 정보 추가)
  public createPointFeature(data: any) {
    //  console.log(data);
    const { caseId, caseDets, coordinate } = data;
    let geometry = [coordinate.x, coordinate.y];

    const feature = new Feature({
      id: 'detPoint',
      geometry: new Point(geometry),
      properties: {
        ...data,
      },
    });

    return feature;
  }

  // 피쳐 배열 리턴
  public createPointFeatures(datas: any[]) {
    return datas?.map((data) => {
      return this.createPointFeature(data);
    });
  }
}

export default PointModule;
