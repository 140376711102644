import React, { useEffect, useRef } from 'react';
import { PagingTableStyle } from './_style';
import { TableProps } from './_interface';
import { useRecoilState } from 'recoil';
import { themeState } from '../../store/Atoms';
import Pagination from './Pagination';
import { PageProps, PaginationProps } from './_interface';

const PagingTable = ({ param, setParam, totalPages, btnSize, thead, tbody, caption, colgroup, height, tbodyHeight, type, tbodyId, width }: TableProps & PaginationProps<PageProps>) => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const headRef = useRef<HTMLDivElement>(null);
  const [themeMode] = useRecoilState(themeState);

  return (
    <>
      <PagingTableStyle.Wrap height={height} width={width}>
        <PagingTableStyle.Thead ref={headRef} type={type}>
          <table>
            <caption>{caption}</caption>
            <colgroup>
              {colgroup?.map((i, index) => {
                return <col key={index} style={{ width: i }} />;
              })}
            </colgroup>
            <thead>{thead}</thead>
          </table>
        </PagingTableStyle.Thead>
        <PagingTableStyle.Tbody className={themeMode === 'light' ? 'cScroll light' : 'cScroll'} ref={bodyRef} style={{ maxHeight: tbodyHeight }} type={type} id={tbodyId}>
          <table>
            <caption>{caption}</caption>
            <colgroup>
              {colgroup?.map((i, index) => {
                return <col key={index} style={{ width: i }} />;
              })}
            </colgroup>
            <tbody>{tbody}</tbody>
          </table>
        </PagingTableStyle.Tbody>
      </PagingTableStyle.Wrap>

      {/* 페이지네이션  */}
      <Pagination param={param} setParam={setParam} totalPages={totalPages} btnSize={btnSize} />
    </>
  );
};

export default PagingTable;
