import OlCore from 'olMap/OlCore';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style.js';
import { Collection, Feature, Overlay } from 'ol';

import Select from 'ol/interaction/Select.js';
import { click } from 'ol/events/condition';
import GeoJSON from 'ol/format/GeoJSON.js';
import { transProjection } from 'olMap/projection';
// data
import geojsonData from '../data/wtl_geojson.json';
import PipeLineModule from 'olMap/Module/PipeLineModule';

class ObjectPipeLine extends PipeLineModule {
  private pointLayer: VectorLayer<VectorSource>;
  private pipeLayer: VectorLayer<VectorSource>;

  private selectClick?: Select;

  //레이어 초기화
  constructor(core: OlCore) {
    super(core);

    //테스트용 포인트 레이어
    this.pointLayer = new VectorLayer<VectorSource>({
      properties: {
        id: 'testPointLayer',
        buffer: true,
      },
      visible: true,
      zIndex: 3,
    });

    this.pipeLayer = new VectorLayer<VectorSource>({
      properties: {
        id: 'pipeLineLayer',
        buffer: true,
      },
      visible: true,
      zIndex: 3,
    });

    this.setLayers([this.pipeLayer, this.pointLayer]);
  }

  /* ------------------ DRAWING ------------------ */

  // pipeLine : map 지도 조회 데이터 그리기
  public draw(data: any) {
    let pipesAll: any[] = [];

    data?.cases?.map((ele: any) => {
      let newPipes = ele?.pipes?.map((item) => ({ ...item, caseId: ele.caseId })) || [];

      pipesAll.push(...newPipes);
    });

    const features = this.createPipeLineFeatures(pipesAll, 'pipeLineLayer');
    const source = this.createSource(features);

    this.pipeLayer.setSource(source);
    this.pipeLayer.setStyle(this.basicLineSt);

    this.moveLayer('pipeLineLayer');
  }

  //geoJson 데이터
  public drawTest() {
    // geoJson 데이터 features 추출
    //  const features = new GeoJSON().readFeatures(geojsonData); // 기본 방식
    //  const features = this.createFeatures(geojsonData.features); // projection 변환 코드 포함 되어야함

    let customFeatures = geojsonData.features.map((ele) => ({ ...ele, geometry: { ...ele.geometry, coordinates: [ele.properties.x, ele.properties.y] } }));

    const features = this.createFeatures(customFeatures); // projection 변환 코드 포함

    // 'EPSG:5186'
    const source = this.createSource(features);

    this.pointLayer.setSource(source);
    this.pointLayer.setStyle(this.basicPointSt); // 스타일 추가

    // 해당 레이어로 이동
    //  this.moveLayer('pipeLineLayer');
  }

  /* ------------------ SELECT / EVENT ------------------ */

  // 지도 move 이벤트
  public addMapMoveEvent() {
    let selected: any = null;

    this.core.mapInstance.on('click', async (evt) => {
      // 현재 선택된 피쳐 없을때
      let activeLength: any = this.selectClick?.getFeatures()?.getArray()?.length;
      if (selected !== null) {
        if (activeLength === 0) {
          selected.setStyle(undefined);
          //  selected.setStyle(this.basicLineSt);
          selected = null;
        }
      }
    });

    //pointermove
    this.core.mapInstance.on('pointermove', async (evt) => {
      // 현재 선택된 피쳐 없을때
      let activeLength: any = this.selectClick?.getFeatures()?.getArray()?.length;
      let activeFeature = this.selectClick?.getFeatures()?.getArray()?.[0] || undefined;
      let activeProperties = activeFeature?.getProperties()?.properties;
      const activeSmartPipeNum = activeProperties?.smartPipeNum;

      const selectedProperties = selected?.getProperties()?.properties;
      const selectedSmartPipeNum = selectedProperties?.smartPipeNum;

      if (selected !== null) {
        if (activeSmartPipeNum !== selectedSmartPipeNum) {
          selected.setStyle(undefined);
          //  selected.setStyle(this.basicLineSt);
          selected = null;
        }
      }

      // 일반 피쳐 감지
      let feature = this.core.mapInstance.forEachFeatureAtPixel(evt.pixel, (feature: any) => {
        const properties = feature?.getProperties()?.properties;
        //   console.log(properties);
        const isSmartPipe = properties?.isSmartPipe === 1;
        const smartPipeNum = properties?.smartPipeNum;
        console.log(smartPipeNum);

        if (isSmartPipe) {
          if (activeSmartPipeNum !== smartPipeNum) {
            selected = feature;

            feature.setStyle(this.basicLineStHover);
          }
          return feature;
        } else {
        }
      });
    });
  }

  // 지도 클릭 이벤트
  public addMapClickEvent() {
    this.core.mapInstance.addEventListener('click', (evt: any) => {
      this.core.mapInstance.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        let properties = feature.getProperties().properties;
        const isSmartPipe = properties.isSmartPipe === 1; // 스마트 파이프 유무
        if (isSmartPipe) {
          let length = this.selectClick?.getFeatures()?.getLength() || 0; // 현재 활성화된 파이프 있을때
          if (length > 0) {
            // this.resetSelect(); // 팝업 오버레이 안나오는 현상 해결
          }
        }
      });
    });
  }

  // Select 이벤트
  public addLineSelectClick({ setOverlayData }) {
    let selectClick = new Select({
      layers: [this.pipeLayer],
      condition: click,
      // toggleCondition: click,
      style: (feature, resolution) => {
        return this.lineSelectStyle(feature);
      },
      filter: (feature: any) => {
        let properties = feature.getProperties().properties;
        const isSmartPipe = properties.isSmartPipe === 1; // 스마트 파이프 유무
        if (isSmartPipe) {
          return feature;
        }
      },
      removeCondition: (MapBrowserEvent) => {
        // 지도 어디든 클릭시 초기화
        this.resetSelect(); // 현재 select 초기화
        setOverlayData((prev) => ({ ...prev, open: false })); // 오버레이 닫기
        return false;
      },
    });

    this.selectClick = selectClick;

    this.selectClick.on('select', (e) => {
      //e.target.getFeatures는 Collection으로 반환한다.
      const targetCollection = e.target.getFeatures() as Collection<Feature>;
      if (targetCollection.getLength()) {
        const feature = targetCollection.getArray()[0];
        if (feature) {
          let properties = feature.getProperties().properties;
          // 오버레이 팝업 데이터 저장
          let overlayList = properties?.smartPipeDets?.map((det) => {
            return { ...det };
          });
          setOverlayData((prev) => ({ ...prev, overlayList })); // 오버레이 팝업 데이터 전달

          this.openOverlay({ feature, setOverlayData }); // 오버레이 열기
        }
      } else {
        setOverlayData((prev) => ({ ...prev, open: false })); // 오버레이 닫기
      }
    });

    // 지도에 클릭 이벤트 추가
    this.core.mapInstance.addInteraction(this.selectClick);
  }

  // 파이프 레이어에서 피쳐 찾기
  public getFeatureById({ pipeNum }) {
    let targetFeature;

    let features: Feature[] = this.pipeLayer?.getSource()?.getFeatures() || [];

    features?.map((feature: Feature) => {
      let properties = feature.getProperties().properties;

      if (properties?.pipeNum === pipeNum) {
        targetFeature = feature;
      }
    });

    return targetFeature;
  }

  // Select 객체 - 선택할 피쳐 넣기(지도 직접클릭 x)
  public dynamicSelect(feature: Feature) {
    //  feature.setStyle(this.lineSelectStyle);
    this.selectClick?.getFeatures()?.clear(); // 하나씩만 활성화
    this.selectClick?.getFeatures()?.push(feature);
  }

  // Select 객체 - 선택할 피쳐 넣기(지도 직접클릭 x)
  public resetSelect() {
    this.selectClick?.getFeatures()?.clear();
  }

  /* ------------------ STYLING------------------ */
  // 기본 포인트 스타일
  public basicPointSt(feature, resolution) {
    const image = new CircleStyle({
      radius: 5,
      fill: new Fill({
        color: 'yellow',
      }),
      stroke: new Stroke({ color: 'red', width: 1 }),
    });
    // 포인트

    return new Style({
      image,
      // zIndex: 999
    });
  }

  // 파이프 라인 스타일 - 기본
  public basicLineSt(feature, resolution) {
    let properties = feature.getProperties().properties;

    const isSmartPipe = properties.isSmartPipe === 1; // 스마트 파이프 유무
    const isError = isSmartPipe && properties.smartPipeStatus !== 0;

    const stroke = new Stroke({
      width: 20,
      // color: isSmartPipe ? (isError ? 'rgba(240, 26, 26, 0.4)' : 'rgba(41, 142, 255, 0.4)') : '#00000075',
      // color: isSmartPipe ? (isError ? 'rgba(240, 26, 26, 0.7)' : 'rgba(41, 142, 255, 0.7)') : 'rgba(0, 0, 0, 0.332)',
      color: isSmartPipe ? 'rgba(41, 142, 255, 0.7)' : 'rgba(0, 0, 0, 0.332)',
      lineCap: 'butt',
      // lineCap: 'round', //butt
    });
    const underStroke = new Stroke({
      width: 20,
      color: '#fff',
      lineCap: 'butt',
      // lineCap: 'round', //butt
    });

    // 라인
    return [
      new Style({
        stroke: underStroke,
      }),
      new Style({
        stroke,
      }),
    ];
  }

  // 파이프 라인 스타일 - hover
  public basicLineStHover(feature, resolution) {
    let properties = feature.getProperties().properties;

    const isSmartPipe = properties.isSmartPipe === 1; // 스마트 파이프 유무
    const isError = isSmartPipe && properties.smartPipeStatus !== 0;

    const stroke = new Stroke({
      width: 20,
      color: isSmartPipe ? 'rgba(41, 141, 255, 1)' : 'transparent',
      lineCap: 'butt',
      // lineCap: 'round', //butt
    });

    // 라인
    return [
      new Style({
        stroke,
      }),
    ];
  }

  // 파이프 라인 활성화 스타일
  public lineSelectStyle(feature) {
    let properties = feature.getProperties().properties;

    const isSmartPipe = properties.isSmartPipe === 1; // 스마트 파이프 유무
    const isError = isSmartPipe && properties.smartPipeStatus !== 0;

    const shadowStroke = new Stroke({
      width: 36,
      color: 'rgba(0, 255, 200, 0.2)',
      lineCap: 'butt', //butt
    });
    const backStroke = new Stroke({
      width: 28,
      color: '#ffffff',
      lineCap: 'butt', //butt
    });
    const stroke = new Stroke({
      width: 20,
      color: isError ? '#F01A1A' : '#0076FF',
      lineCap: 'butt', //butt
    });

    return [
      // new Style({
      //   stroke: shadowStroke,
      //   zIndex: 0,
      // }),
      new Style({
        stroke: backStroke,
        zIndex: 1,
      }),
      new Style({
        stroke,
        zIndex: 2,
      }),
    ];
  }

  // 파이프 컬러
  public getPipeColor(pipeType: string, alpha: number) {
    let color = '';

    switch (pipeType) {
      case 'water':
        color = `rgba(11,101,245,${alpha})`;
        break;
      case 'power':
        color = `rgba(240,47,29,${alpha})`;
        break;
      case 'sewage':
        color = `rgba(206,56,245,${alpha})`;
        break;
      case 'oil':
        color = `rgba(190,106,56,${alpha})`;
        break;
      case 'rain':
        color = `rgba(206,56,245,${alpha})`;
        break;
      case 'gas':
        color = `rgba(249,181,55,${alpha})`;
        break;
      case 'network':
        color = `rgba(74,202,56,${alpha})`;
        break;
      case 'heating':
        color = `rgba(102,47,17,${alpha})`;
        break;
    }

    return color;
  }

  private onStyle(feature: any) {
    const pipeType = feature.getProperties().properties.pipe_type;
    const diameter = Number(feature.getProperties().properties.figures.diameter);
    const resol = this.core.mapInstance.getView().getResolution();

    return [
      new Style({
        stroke: new Stroke({
          width: 2,
          color: this.getPipeColor(pipeType, 1),
          lineCap: 'butt',
        }),
        zIndex: 3,
      }),
      new Style({
        stroke: new Stroke({
          width: resol ? ((diameter * 0.86) / 1000) * (0.00001 / resol) : 1,
          color: this.getPipeColor(pipeType, 0.4),
          lineCap: 'butt',
        }),
        zIndex: 2,
      }),
      new Style({
        stroke: new Stroke({
          width: resol ? (diameter / 1000) * (0.00001 / resol) : 1,
          color: `rgba(255, 255, 255, .8)`,
          lineCap: 'butt',
        }),
        zIndex: 1,
      }),
    ];
  }

  private offStyle(feature: any) {
    const pipeType = feature.getProperties().properties.pipe_type;
    return [
      new Style({
        stroke: new Stroke({
          width: 2,
          color: this.getPipeColor(pipeType, 1),
          lineCap: 'butt',
        }),
        zIndex: 3,
      }),
    ];
  }

  private selectStyle(feature: any) {
    const pipeType = feature.getProperties().properties.pipe_type;
    const diameter = Number(feature.getProperties().properties.figures.diameter);
    const resol = this.core.mapInstance.getView().getResolution();

    return [
      new Style({
        stroke: new Stroke({
          width: 4,
          color: this.getPipeColor(pipeType, 1),
          lineCap: 'butt',
        }),
        zIndex: 3,
      }),
      new Style({
        stroke: new Stroke({
          width: resol ? ((diameter * 0.86) / 1000) * (0.00001 / resol) : 1,
          color: this.getPipeColor(pipeType, 0.4),
          lineCap: 'butt',
        }),
        zIndex: 2,
      }),
      new Style({
        stroke: new Stroke({
          width: resol ? ((diameter * 1.3) / 1000) * (0.00001 / resol) : 1,
          color: `rgba(255, 255, 255, .8)`,
          lineCap: 'butt',
        }),
        zIndex: 1,
      }),
    ];
  }
  /* ------------------ STYLE ------------------ */
}

export default ObjectPipeLine;
