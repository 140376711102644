import React, { useEffect, useState } from 'react';
import { LoginStyle } from './_style';
import { useLocation, useNavigate } from 'react-router-dom';
import useMutateHook from 'hooks/reactQuery/useMutateHook';
import { fetchLogin } from 'service/common';
import useInputChange from 'hooks/useInputChange';
import { useSetRecoilState } from 'recoil';
import { siteInfoState, userInfoState } from 'store/Atoms';
// import { qsData, request } from '../service/api/common';
// import useParamChange from '../hooks/useParamChange';

const Login = () => {
  const setUserInfo = useSetRecoilState(userInfoState);
  const setSiteInfo = useSetRecoilState(siteInfoState);
  const location = useLocation();
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [param, setParam] = useState({
    id: '',
    password: '',
  });
  const [type, setType] = useState(false);

  const { mutate: loginMutate } = useMutateHook({ fetchApi: fetchLogin });
  const [form, setForm, onChange] = useInputChange({ email: '', password: '' });

  // 로그인 요청
  const onLogin = async () => {
    await loginMutate(form, {
      async onSuccess(data: any, variables, context) {
        // 세션 저장
        sessionStorage.setItem('token', data?.token);
        //recoil 저장
        setUserInfo({ ...data }); // 유저 정보
        setSiteInfo({ siteId: data?.sites[0]?.siteId }); // 현장 정보

        if (data?.initPw === 1) {
          window.location.href = '/pwChange';
        } else {
          // 페이지 이동
          window.location.href = '/';
        }
      },
      async onError(error: any, variables, context) {
        let status = error?.data?.status;
        let msg = error?.data?.message;
        await setErrorMsg(msg);
        setError(true);
      },
    });
  };

  //비밀번호  show & hide
  const onTypeToggle = () => {
    setType(!type);
  };

  // 토큰 확인 후 페이지 이동
  useEffect(() => {
    if (sessionStorage.getItem('token')) {
      navigate('/dashboard');
    }
  }, []);

  // 비밀번호 초기화 버튼 클릭
  const onClickResetPw = () => {
    navigate('/pwReset');
  };

  return (
    <LoginStyle.Wrap>
      <LoginStyle.Box>
        {/* <LoginStyle.Logo></LoginStyle.Logo> */}
        <LoginStyle.MainTitle>로그인</LoginStyle.MainTitle>
        <LoginStyle.Title>DTX IoT</LoginStyle.Title>
        <LoginStyle.InputWrap className={error ? 'id error' : 'id'}>
          <LoginStyle.Input type={'text'} id={'email'} placeholder={'이메일을 입력해주세요'} name={'email'} onChange={onChange} autoComplete="new-password" />
        </LoginStyle.InputWrap>
        <LoginStyle.InputWrap className={error ? 'password error' : 'password'}>
          <LoginStyle.Input
            type={type ? 'text' : 'password'}
            id={'password'}
            placeholder={'Password'}
            name={'password'}
            onChange={onChange}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                onLogin();
              }
            }}
            autoComplete="new-password"
          />
          <LoginStyle.ToggleTypeBtn type={'button'} onClick={onTypeToggle}>
            {type ? 'Hide' : 'Show'}
          </LoginStyle.ToggleTypeBtn>
        </LoginStyle.InputWrap>
        <LoginStyle.Msg className={error ? 'error' : undefined}>{errorMsg || 'ID 또는 비밀번호를 다시 확인하세요.'}</LoginStyle.Msg>

        <LoginStyle.ResetPwBtn onClick={onClickResetPw}>비밀번호 초기화</LoginStyle.ResetPwBtn>
        <LoginStyle.Button type={'button'} onClick={onLogin}>
          Log in
        </LoginStyle.Button>
      </LoginStyle.Box>
      {/* <LoginStyle.CopyRight>
        Copyright 2024. <b>Movements</b> All rights reserved.
      </LoginStyle.CopyRight> */}
    </LoginStyle.Wrap>
  );
};

export default Login;
