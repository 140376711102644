import React from 'react';
import PopupFrame from './PopupFrame';
import Table from 'components/atom/Table';
import { useQueryState } from 'hooks/reactQuery/useQueryState';
import { getLoginLog } from 'service/common';

// 인증 이력 보는 팝업
function PopupLoginHistory({ close }) {
  const { data: loginLogData } = useQueryState(['user', 'login', 'log'], () => getLoginLog());

  return (
    <>
      <PopupFrame title="인증 이력" close={close} $width={700}>
        <div style={{ width: '100%', minHeight: '344px', border: '1px solid #e5e5e5' }}>
          <Table
            tbodyId="loginHistoryList"
            caption={'인증 이력 목록'}
            colgroup={['30%', '30%', '20%', 'auto']}
            tbodyHeight={280}
            thead={
              <tr>
                <th scope={'col'}>일시</th>
                <th scope={'col'}>계정</th>
                <th scope={'col'}>IP</th>
                <th scope={'col'}>결과</th>
              </tr>
            }
            tbody={
              <>
                {loginLogData?.map((ele, idx) => (
                  <tr key={'loginHistory:' + idx} className={`${ele.message === '실패' && 'error'}`}>
                    <td>{ele.date}</td>
                    <td>{ele.email}</td>
                    <td>{ele.ip}</td>
                    <td>{ele.message}</td>
                  </tr>
                ))}
              </>
            }
          />
        </div>
      </PopupFrame>
    </>
  );
}

export default PopupLoginHistory;
